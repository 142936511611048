import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactPersonDto } from '@app/dto/patient-card/contact-person.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-contact-person-dialog',
  templateUrl: './contact-person-edit-dialog.component.html'
})
export class ContactPersonEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    kinship: new FormControl(this.data?.kinship, Validators.required),
    name: new FormControl(this.data?.name),
    surname: new FormControl(this.data?.surname),
    phone: new FormControl(this.data?.phone, Validators.required),
    confirmed: new FormControl(this.data?.confirmed),
  });
  
  constructor(
    public dialogRef: MatDialogRef<ContactPersonEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: ContactPersonDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  ngOnInit(): void {
    // console.log(this.data);
  }

  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: ContactPersonDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: ContactPersonDto) {
    this.cardService.authAddContactPerson(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: ContactPersonDto) {
    this.cardService.authUpdateContactPerson(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
