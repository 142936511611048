<div [ngClass]="{'edit-card mb-3' : !single}">
  <div class="edit-header" *ngIf="!single">
    <h4 class="m-0">{{ title() }}</h4>
    <button mat-mini-fab class="add-button" color="primary" (click)="onAdd()">
      <mat-icon>add</mat-icon>
    </button>
  </div>


    <table *ngIf="items.length > 0" class="mt-3">
      <!-- Choroby -->
      <ng-container *ngIf="mode === 1">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).dateFrom | slice:0:4  }}</td>
          <td>{{ $any(item).diseases }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Zabiegi -->
      <ng-container *ngIf="mode === 2">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).dateTreatments | slice:0:4 }}</td>
          <td>{{ $any(item).treatments }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Szczepienia -->
      <ng-container *ngIf="mode === 3">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).dateVaccinations }}</td>
          <td>{{ $any(item).vaccinations }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Nałogi -->
      <ng-container *ngIf="mode === 4">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).addictions }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Przeciwwskazania -->
      <ng-container *ngIf="mode === 5">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).contraindications }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Uczulenia -->
      <ng-container *ngIf="mode === 6">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).sitization }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Osoby kontaktowe -->
      <ng-container *ngIf="mode === 7">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).kinship }}</td>
          <td>{{ $any(item).phone }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Lekarze -->
      <ng-container *ngIf="mode === 8">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).academicTitle }}</td>
          <td>{{ $any(item).doctorsName }}</td>
          <td>{{ $any(item).doctorsSurname }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Choroby -->
      <ng-container *ngIf="mode === 9">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).diseases }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Leki -->
      <ng-container *ngIf="mode === 10">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).medications }}</td>
          <td>{{ $any(item).strength }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Urządzenia -->
      <ng-container *ngIf="mode === 11">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).devices }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <!-- Wyniki badań -->
      <ng-container *ngIf="mode === 12">
        <tr *ngFor="let item of items">
          <td>{{ $any(item).nameResults }}</td>
          <td>{{ $any(item).dateMedicalResults | date:"dd.MM.yyyy" }}</td>
          <td>
            <button mat-icon-button color="primary" (click)="onDelete(item)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onEdit(item)" [hidden]="true">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
    </table>


  </div>