import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicationTakenDto } from '@app/dto/patient-card/medication-taken.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-medication-taken-edit-dialog',
  templateUrl: './medication-taken-edit-dialog.component.html'
})
export class MedicationTakenEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    medications: new FormControl(this.data?.medications, Validators.required),
    strength: new FormControl(this.data?.strength),
    dosage: new FormControl(this.data?.dosage),
    dateFrom: new FormControl(this.data?.dateFrom),
    dateTo: new FormControl(this.data?.dateTo),
    description: new FormControl(this.data?.description)
  });
  
  constructor(
    public dialogRef: MatDialogRef<MedicationTakenEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: MedicationTakenDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  searchControl = new FormControl(null);
  filteredMedications: any;
  searchLoading = false;

  onSelected(event: any) {
    this.searchControl.setValue(null);
    this.dialogForm.controls['medications'].setValue(event.option.value.nazwaProduktuLeczniczego);
    this.dialogForm.controls['strength'].setValue(event.option.value.moc);
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.filteredMedications = [];
          this.searchLoading = true;
        }),
        switchMap(value => this.cardService.authLekiByKeyword(value))
      )
      .subscribe(
        (data: any) => {
        if (data == undefined) {
          this.filteredMedications = [];
        } else {
          this.filteredMedications = data;
        }
        this.searchLoading = false;
      },
      error => {
        this.searchLoading = false;
      });
  }


  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: MedicationTakenDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: MedicationTakenDto) {
    this.cardService.authAddMedicationTaken(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: MedicationTakenDto) {
    
    this.cardService.authUpdateMedicationTaken(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
