import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalResultDto } from '@app/dto/medical-result.dto';
import { PatientCardResponseDto } from '@app/dto/patient-card/patient-card-response.dto';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-medical-results-dialog',
  templateUrl: './medical-results-dialog.component.html',
  styles: [
  ]
})
export class MedicalResultsDialogComponent implements OnInit {

  
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: MedicalResultDto[]) { }
      
  eventsSubject: Subject<void> = new Subject<void>();

  emitAddContact() {
    this.eventsSubject.next();
  }

  openDialog() {

  }

  ngOnInit(): void {

  }


}
