import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { UserPermissionDto } from '@app/dto/user-permission.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-settings-permissions',
  templateUrl: './settings-permissions.component.html',
  styleUrls: [
    './settings-permissions.component.scss'
  ]
})
export class SettingsPermissionsComponent implements OnInit {

  permissionsForm?: FormGroup;


  private _permissions: UserPermissionDto | undefined;

  @Input() set permissions(value: UserPermissionDto | undefined) {
    this._permissions = value;
    if (value) {
      this.permissionsForm = new FormGroup({
        roleAnonimPreview: new FormControl(value.roleAnonimPreview),
        roleUsersmsPreview: new FormControl(value.roleUsersmsPreview),
        roleDoctorPreview: new FormControl(value.roleDoctorPreview),
        roleUserPreview: new FormControl(value.roleUserPreview),
        roleDoctorEdit: new FormControl(value.roleDoctorEdit),
        roleUserEdit: new FormControl(value.roleUserEdit),
      });
    }
  };
  get permissions(): UserPermissionDto | undefined {
    return this._permissions;
  }

  constructor(private snackService: SnackService, private authService: AuthService) { }

  ngOnInit(): void {

  }

  onSubmit() {
    if (this.permissionsForm == undefined || this.permissions == undefined)
      return;

    const value = this.permissionsForm.value as UserPermissionDto;

    value.dateAdd = this.permissions.dateAdd;
    value.id = this.permissions.id;
    value.permissionsTo = this.permissions.permissionsTo;
    value.userId = this.permissions.userId;
    value.username = this.permissions.username;

    this.authService.updatePermissions(value)
    .subscribe(
      data => {
        // console.log(data);
        this.snackService.success("Zapisano uprawnienia");
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }
}
