import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VaccinationDto } from '@app/dto/patient-card/vaccination.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-vaccination-edit-dialog',
  templateUrl: './vaccination-edit-dialog.component.html'
})
export class VaccinationEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    vaccinations: new FormControl(this.data?.vaccinations, Validators.required),
    dateVaccinations: new FormControl(this.data?.dateVaccinations, Validators.required),
    certificateId: new FormControl(this.data?.certificateId),
    description: new FormControl(this.data?.description)
  });
  
  constructor(
    public dialogRef: MatDialogRef<VaccinationEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: VaccinationDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  ngOnInit(): void {
    // console.log(this.data);
  }

  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: VaccinationDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: VaccinationDto) {
    this.cardService.authAddVaccination(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: VaccinationDto) {
    this.cardService.authUpdateVaccination(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
