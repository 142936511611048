import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AddictionEditDialogComponent } from './patient-card/dialogs/addiction-edit-dialog/addiction-edit-dialog.component';
import { AttendingDoctorsDialogComponent } from './patient-card/dialogs/attending-doctors-dialog/attending-doctors-dialog.component';
import { ContraindicationEditDialogComponent } from './patient-card/dialogs/contraindication-edit-dialog/contraindication-edit-dialog.component';
import { DeleteConfirmDialogComponent } from './patient-card/dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { ExistingDiseaseEditDialogComponent } from './patient-card/dialogs/existing-disease-edit-dialog/existing-disease-edit-dialog.component';
import { MedicalDiseaseEditDialogComponent } from './patient-card/dialogs/medical-disease-edit-dialog/medical-disease-edit-dialog.component';
import { MedicalInfromsDialogComponent } from './patient-card/dialogs/medical-infroms-dialog/medical-infroms-dialog.component';
import { MedicalTreatmentEditDialogComponent } from './patient-card/dialogs/medical-treatment-edit-dialog/medical-treatment-edit-dialog.component';
import { PatientCardDialogComponent } from './patient-card/dialogs/patient-card-dialog/patient-card-dialog.component';
import { SensitizationEditDialogComponent } from './patient-card/dialogs/sensitization-edit-dialog/sensitization-edit-dialog.component';
import { VaccinationEditDialogComponent } from './patient-card/dialogs/vaccination-edit-dialog/vaccination-edit-dialog.component';
import { EditListComponent } from './edit-list/edit-list.component';
import { PatientCardService } from './patient-card/patient-card.service';
import { SnackService } from './services/snack.service';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { PhotoDialogComponent } from './patient-card/dialogs/photo-dialog/photo-dialog.component';
import { ContactPersonEditDialogComponent } from './patient-card/dialogs/contact-person-edit-dialog/contact-person-edit-dialog.component';
import { DoctorEditDialogComponent } from './patient-card/dialogs/doctor-edit-dialog/doctor-edit-dialog.component';
import { ExistingDiseasesDialogComponent } from './patient-card/dialogs/existing-diseases-dialog/existing-diseases-dialog.component';
import { LoginAnonimComponent } from './login-anonim/login-anonim.component';
import { SmsDialogComponent } from './patient-card/dialogs/sms-dialog/sms-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsPermissionsComponent } from './settings-permissions/settings-permissions.component';
import { MedicalHistoryDialogComponent } from './patient-card/dialogs/medical-history-dialog/medical-history-dialog.component';
import { AddictionsDialogComponent } from './patient-card/dialogs/addictions-dialog/addictions-dialog.component';
import { ContraindicationsDialogComponent } from './patient-card/dialogs/contraindications-dialog/contraindications-dialog.component';
import { MedicalTreatmentsDialogComponent } from './patient-card/dialogs/medical-treatments-dialog/medical-treatments-dialog.component';
import { VaccinationsDialogComponent } from './patient-card/dialogs/vaccinations-dialog/vaccinations-dialog.component';
import { SensitizationsDialogComponent } from './patient-card/dialogs/sensitizations-dialog/sensitizations-dialog.component';
import { AttachedDeviceEditDialogComponent } from './patient-card/dialogs/attached-device-edit-dialog/attached-device-edit-dialog.component';
import { AttachedDevicesDialogComponent } from './patient-card/dialogs/attached-devices-dialog/attached-devices-dialog.component';
import { MedicationTakenEditDialogComponent } from './patient-card/dialogs/medication-taken-edit-dialog/medication-taken-edit-dialog.component';
import { MedicationsTakenDialogComponent } from './patient-card/dialogs/medications-taken-dialog/medications-taken-dialog.component';
import { RegisterComponent } from './register/register.component';
import { NotAuthGuard } from './auth/not-auth.guard';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UserDataComponent } from './register-data/register-data.component';
import { ContactPersonsDialogComponent } from './patient-card/dialogs/contact-persons-dialog/contact-persons-dialog.component';
import { SettingsRegisterComponent } from './settings-register/settings-register.component';
import { SmsPinDialogComponent } from './sms-pin-dialog/sms-pin-dialog.component';
import { MedicalCardComponent } from './medical-card/medical-card.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SelectionListComponent } from './shared/components/selection-list/selection-list.component';
import { SettingsLayoutComponent } from './settings-layout/settings-layout.component';
import { MedicalCardPageComponent } from './medical-card-page/medical-card-page.component';
import { PhoneDialogComponent } from './patient-card/dialogs/phone-dialog/phone-dialog.component';
import { PowiadomDialogComponent } from './patient-card/dialogs/powiadom-dialog/powiadom-dialog.component';
import { SettingsPasswordComponent } from './settings-password/settings-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { MedicalResultsDialogComponent } from './patient-card/dialogs/medical-results-dialog/medical-results-dialog.component';
import { MedicalResultsEditDialogComponent } from './patient-card/dialogs/medical-results-edit-dialog/medical-results-edit-dialog.component';
import { LightboxModule } from 'ngx-lightbox';
import { FirstAidComponent } from './first-aid/first-aid.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    LoginComponent,
    PatientCardComponent,
    PatientCardDialogComponent,
    MedicalInfromsDialogComponent,
    MedicalDiseaseEditDialogComponent,
    MedicalHistoryDialogComponent,
    ExistingDiseaseEditDialogComponent,
    PhotoDialogComponent,
    SensitizationEditDialogComponent,
    SensitizationsDialogComponent,
    VaccinationEditDialogComponent,
    VaccinationsDialogComponent,
    ContraindicationEditDialogComponent,
    ContraindicationsDialogComponent,
    MedicalTreatmentEditDialogComponent,
    MedicalTreatmentsDialogComponent,
    AddictionEditDialogComponent,
    AddictionsDialogComponent,
    AttendingDoctorsDialogComponent,
    DeleteConfirmDialogComponent,
    EditListComponent,
    ContactPersonEditDialogComponent,
    DoctorEditDialogComponent,
    ExistingDiseasesDialogComponent,
    LoginAnonimComponent,
    SmsDialogComponent,
    PowiadomDialogComponent,
    PhoneDialogComponent,
    SettingsComponent,
    SettingsPermissionsComponent,
    AttachedDeviceEditDialogComponent,
    AttachedDevicesDialogComponent,
    MedicationTakenEditDialogComponent,
    MedicationsTakenDialogComponent,
    RegisterComponent,
    HomeComponent,
    NotFoundComponent,
    UserDataComponent,
    ContactPersonsDialogComponent,
    SettingsRegisterComponent,
    SmsPinDialogComponent,
    MedicalCardComponent,
    MedicalCardPageComponent,
    SelectionListComponent,
    SettingsLayoutComponent,
    SettingsPasswordComponent,
    ResetPasswordComponent,
    PasswordChangeComponent,
    MedicalResultsDialogComponent,
    MedicalResultsEditDialogComponent,
    FirstAidComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    HttpClientModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    NgxMatFileInputModule,
    LightboxModule,
    AngularSvgIconModule.forRoot()
  ],
  providers: [AuthService, AuthGuard, NotAuthGuard, PatientCardService, SnackService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
