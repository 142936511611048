  import { Component, OnInit } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '@app/services/snack.service';
  
  import { AuthService } from './../auth/auth.service';
  
  @Component({
    selector: 'app-login-anonim',
    templateUrl: './login-anonim.component.html',
    styles: [
    ]
  })
  export class LoginAnonimComponent implements OnInit {
    form!: FormGroup;
    private formSubmitAttempt!: boolean;
    private returnUrl: string = "/";
    username: string|null = null;
    ready: boolean = false;
  
    constructor(
      private fb: FormBuilder,
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      private snackService: SnackService
    ) {
      this.authService.signOut();
    }
  
    ngOnInit() {
      this.returnUrl = '/patient-card';
      
      this.route.paramMap.subscribe(params => {
        this.username = params.get('username');
        let pin = params.get('pin');
        
        this.form = this.fb.group({
          userName: [this.username, Validators.required],
          pin: ['', Validators.required]
        });
        
        if (this.username != null && pin != null) {
          this.authService.loginAnonim(this.username, pin)
          .subscribe(
            data => {
              if (this.authService.checkToken(data)) {
                this.authService.signIn(data);
                this.router.navigate([this.returnUrl]);
              }
              else {
                this.router.navigate([this.returnUrl + "/" + this.username]);
              }
            },
            error => {
              if (error.status == 401) {
                this.snackService.error("Niepoprawny login lub PIN");
              }
              this.router.navigate([this.returnUrl + "/" + this.username]);
            });
        }
        else {
          this.ready = true;
        }
      });
      
    }
  
    isFieldInvalid(field: string) {
      return (
        (!this.form.get(field)?.valid && this.form.get(field)?.touched) ||
        (this.form.get(field)?.untouched && this.formSubmitAttempt)
      );
    }
  
    onSubmit() {
      if (this.form.valid) {
        this.authService.loginAnonim(this.form.value.userName, this.form.value.pin)
        .subscribe(
          data => {
            if (this.authService.checkToken(data)) {
              this.authService.signIn(data);
              this.router.navigate([this.returnUrl]);
            }
            else {
              // console.log('zly token');
              this.snackService.error("Nieoczekiwany błąd");
            }
          },
          error => {
            if (error.status == 401) {
              this.snackService.error("Niepoprawny login lub PIN");
            }
            else {
              this.snackService.error(error.message);
            }
          }
        )
      }
      this.formSubmitAttempt = true;
    }
  }
