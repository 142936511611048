<div class="mb-5">
  <div class="px-4 px-lg-6">
    <h3 class="mb-3 pb-1">Resetuj hasło</h3>
  </div>



  <div class="px-4 px-lg-6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <mat-card>
        <mat-card-content>
          <mat-label>Nazwa użytkownika</mat-label>
          <mat-form-field class="w-100" appearance="fill">
            <input matInput formControlName="user" required>
          </mat-form-field>
    
          <mat-label>PIN SMS</mat-label>
          <mat-form-field class="w-100" appearance="fill">
            <input matInput formControlName="pin" required>
          </mat-form-field>

          <mat-label>Nowe hasło</mat-label>
          <mat-form-field class="w-100" appearance="fill">
            <input matInput formControlName="newPassword" required>
          </mat-form-field>

          <mat-label>Potwierdź nowe hasło</mat-label>
          <mat-form-field class="w-100" appearance="fill">
            <input matInput formControlName="conPassword" required>
          </mat-form-field>
    
        </mat-card-content>
        <mat-card-actions>
          <button mat-flat-button color="primary" type="submit" [disabled]="form.invalid">Wyślij</button>
        </mat-card-actions>
      </mat-card>
    
    
      </form>
      
  </div>
</div>