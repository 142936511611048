import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  // styles: [`
  //   .mat-flat-button {
  //     height: 40px;
  //   }
  // `]
})
export class DeleteConfirmDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
