import { MaxSizeValidator } from '@angular-material-components/file-input';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PatientCardService } from '@app/patient-card/patient-card.service';

@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html'
})
export class PhotoDialogComponent implements OnInit {

  fileControl: FormControl;
  
  constructor(private cardService: PatientCardService, public dialogRef: MatDialogRef<PhotoDialogComponent>,) {
    this.fileControl = new FormControl(null, [
      Validators.required,
      MaxSizeValidator(500 * 1024)
    ])
  }

  
  ngOnInit(): void {
  }

  onSubmit() {
    // console.log(this.fileControl.errors);

    this.fileControl.markAsTouched();

    if (this.fileControl.invalid)
      return;

    let formData: FormData = new FormData();
formData.append('file', this.fileControl.value);


    this.cardService.authUpdatePhoto(formData).subscribe(
      data => {
        // console.log(data);
        this.dialogRef.close(true);
      },
      error => {
        // console.log(error.status);
        if (error.status == 417) {
          this.addPhoto(formData);
        }
      }
    );
  }

  addPhoto(formData: any) {
    this.cardService.authUploadPhoto(formData).subscribe(
      data => {
        this.dialogRef.close(true);
      },
      error => {
        // console.log(error.message);
      }
    );
  }


}
