import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeOldPasswordDto } from '@app/dto/change-old-password.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-settings-password',
  templateUrl: './settings-password.component.html',
  styles: [
  ]
})
export class SettingsPasswordComponent implements OnInit {

  form = new FormGroup({
    oldPass: new FormControl("", Validators.required),
    newPass: new FormControl("", Validators.required),
    conPass: new FormControl("", Validators.required)
  });

  constructor(private snackService: SnackService, private service: PatientCardService) { }

  ngOnInit(): void {
  }

  onSubmit() {

    if (this.form.invalid)
      return;

    const value = this.form.value;

    if (value.newPass != value.conPass) {
      this.snackService.error("Hasła nie są takie same");
      return;
    }

    const reqData: ChangeOldPasswordDto = {
      newPassword: value.newPass,
      oldPassword: value.oldPass
    };

    this.service.authChangePassword(reqData)
    .subscribe(
      data => {
        this.snackService.success(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
    
  }
}
