<div class="row" *ngIf="ready">
  <div class="col-md-6 offset-md-3">

      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <p>Logowanie anonimowe</p>
        <mat-form-field class="w-100">
          <input matInput placeholder="Username" formControlName="userName" readonly required>
          <!--mat-error *ngIf="isFieldInvalid('userName')">
            Please inform your user name
          </mat-error -->
        </mat-form-field>
        <mat-form-field class="w-100">
          <input matInput type="password" autocomplete="new-password" placeholder="PIN" formControlName="pin" required>
          <!--mat-error *ngIf="isFieldInvalid('userName')">
            Please inform your password
          </mat-error -->
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit">Login</button>
      </form>
    </div>
</div>