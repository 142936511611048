<div class="px-4 px-lg-6">
  <div class="row">
    <div class="col-sm-6">
  
        <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
          <h2 class="mb-3">Logowanie</h2>
        <mat-card>
          <div>
            <mat-form-field class="w-100">
              <input matInput placeholder="Użytkownik" formControlName="userName" required>
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput type="password" placeholder="Hasło" formControlName="password" required>
            </mat-form-field>
          </div>
          <button mat-raised-button color="primary" type="submit">Zaloguj</button>
          <button mat-raised-button color="warn" class="ms-3" type="button" [routerLink]="['/','reset-password']">Resetuj hasło</button>
        </mat-card>
        </form>
      </div>
  </div>
</div>