import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalResultDto } from '@app/dto/medical-result.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { Observable, of } from 'rxjs';

import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-medical-results-edit-dialog',
  templateUrl: './medical-results-edit-dialog.component.html',
  styles: [
  ]
})
export class MedicalResultsEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    dateMedicalResults: new FormControl(this.data?.dateMedicalResults, Validators.required),
    typeResults: new FormControl(this.data?.typeResults, Validators.required),
    nameResults: new FormControl(this.data?.nameResults, Validators.required),
    file: new FormControl(null, Validators.required),
    userDescription: new FormControl(this.data?.userDescription ?? "")
  });
  
  constructor(
    public dialogRef: MatDialogRef<MedicalResultsEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: MedicalResultDto,
  private cardService: PatientCardService,
  private snackService: SnackService) {}

  searchControl = new FormControl(null);
  filteredResults: any;
  searchLoading = false;
  allResults: any[] = [];

  onSelected(event: any) {
    this.searchControl.setValue(null);
    this.dialogForm.controls['typeResults'].setValue(event.option.value.key);
    this.dialogForm.controls['nameResults'].setValue(event.option.value.value);
  }

  ngOnInit() {
    this.cardService.userDictionaryGeneral("TypeResults").subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.allResults.push({
            key, value
          });
        }
      },
      error => {
        this.snackService.error("error.message");
      }
    );
    this.searchControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.filteredResults = [];
          this.searchLoading = true;
        }),
        switchMap(value => this.resultsByKeyword(value))
      )
      .subscribe(
        (data: any) => {
        if (data == undefined) {
          this.filteredResults = [];
        } else {
          this.filteredResults = data;
        }
        this.searchLoading = false;
      },
      error => {
        console.log(error)
        this.searchLoading = false;
      });
  }

  resultsByKeyword(keyword: string): Observable<any[]> {
    const results = this.allResults.filter(r => {
      let str = r.value.toLowerCase();
      let search = keyword.toLowerCase();
      return str.indexOf(search) > -1;
      }
    );

    return of(results);
  }


  async onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

      const value = this.dialogForm.value;

      let expiration = 12;

      await this.cardService.userDictionaryGeneral("TimeValidityResults").toPromise().then(
        data => {
          for (const [k, v] of Object.entries(data)) {
            if (k == value.typeResults) {
              expiration = parseInt(v as string);
            }
          }
        },
        error => {
          return;
        }
      );







      let formData: FormData = new FormData();
      formData.append('file', value.file);

      var date = new Date(value.dateMedicalResults);

      let medicalResult = {
        nameResults: value.nameResults,
        typeResults: value.typeResults,
        userDescription: value.userDescription,
        dateMedicalResults: formatDate(new Date(value.dateMedicalResults), "yyyy-MM-dd", this.locale),
        expirationDate: formatDate(new Date(date.setMonth(date.getMonth() + expiration)), "yyyy-MM-dd", this.locale),
        dateAdd: formatDate(new Date(), "yyyy-MM-dd", this.locale),
        status: true
      } as MedicalResultDto;

      
      this.cardService.uploadFileResult(value.typeResults, formData).subscribe(
        data => {
          medicalResult.idFile = data.id;
          this.addItem(medicalResult);
        },
        error => {
          console.log(error.message);
        }
      );

    // const item: MedicalTreatmentDto = this.dialogForm.value;

    // if (this.dialogForm.value.treatments.tytul) {
    //   item.treatments = this.dialogForm.value.treatments.tytul;
    // }
    
    // if (this.data.id != null) { // edit
    //   item.id = this.data.id;
    //   item.userId = this.data.userId;
    //   item.dateAdd = this.data.dateAdd;
    //   this.updateItem(item);
    // }
    // else { // insert
    //   item.userId = this.data.userId;
    //   item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
    //   this.addItem(item);
    // }
  }

  addItem(item: MedicalResultDto) {
    this.cardService.saveMedicalResult(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano badanie");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: MedicalResultDto) {

  }

}
