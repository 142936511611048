
  import { formatDate } from '@angular/common';
  import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
  import { ContraindicationDto } from '@app/dto/patient-card/contraindication.dto';
import { SmsInfoDto } from '@app/dto/sms-info';
  import { PatientCardService } from '@app/patient-card/patient-card.service';
  import { SnackService } from '@app/services/snack.service';
  
  @Component({
    selector: 'app-sms-dialog',
    templateUrl: './powiadom-dialog.component.html',
    styles: [
    ]
  })
  export class PowiadomDialogComponent implements OnInit {
  
    //formControl: FormControl = new FormControl("", Validators.compose([Validators.pattern('[- +()0-9]{6,}'), Validators.required]));
    //formControlPIN: FormControl = new FormControl("", Validators.required);

    options: SmsInfoDto[] = [];

    dialogForm = new FormGroup({
      description: new FormControl([], Validators.required),
      text: new FormControl("")
    });
    
    constructor(
      public dialogRef: MatDialogRef<PowiadomDialogComponent>,
    private service: PatientCardService,
      //private authService: AuthService,
    private snackService: SnackService) { }
  
    ngOnInit(): void {

      this.service.userDictionary<SmsInfoDto>('sms_trusted_sending_info')
      .subscribe(
        data => {
          if (Array.isArray(data)) {
            this.options = data;
          }
        },
        error => {
            this.snackService.error(error.message);
            this.dialogRef.close();
        }
      )
    }


  
    onSubmit() {

      
      if (this.dialogForm.invalid)
        return;
  
      const value: any = this.dialogForm.value;
      
      this.service.userSmsTrustedSending(value.description, value.text)
      .subscribe(
        data => {
            this.dialogRef.close(true);
            this.snackService.success(data);
        },
        error => {
          this.snackService.error(error.message);
        }
      )
    }

  }
  