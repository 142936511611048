<div class="mb-5">
  <div class="px-4 px-lg-6">
    <h3 class="mb-3 pb-1">Ustawienia</h3>
  </div>

  <div class="mb-4">
    <nav mat-tab-nav-bar [disableRipple]="true" class="px-4 px-lg-6">
      <a
        mat-tab-link
        routerLinkActive
        #rla1="routerLinkActive"
        [routerLink]="['/','settings','profile']"
        [active]="rla1.isActive"
      >
        Profil
      </a>
      <a
        mat-tab-link
        routerLinkActive
        #rla2="routerLinkActive"
        [routerLink]="['/','settings','permissions']"
        [active]="rla2.isActive"
      >
        Uprawnienia
      </a>
      <a
        mat-tab-link
        routerLinkActive
        #rla3="routerLinkActive"
        [routerLink]="['/','settings','password']"
        [active]="rla3.isActive"
      >
        Hasło
      </a>
    </nav>
  </div>

  <div class="px-4 px-lg-6">
    <router-outlet></router-outlet>
  </div>
</div>