<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Nałóg</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nałóg</mat-label>
          <textarea matInput formControlName="addictions" required></textarea>
          <mat-error *ngIf="dialogForm.controls['addictions'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Data inicjacji</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateFrom">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Data porzucenia</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="dateToAdd">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" type="submit">Zapisz</button>
  </div>
  </form>
  