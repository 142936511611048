<h2 mat-dialog-title>Informacje dodatkowe</h2>
<mat-dialog-content>

  <div class="row">
    <div class="col-lg-6">

      <app-edit-list
        [mode] = "1"
        [userid] = "data.patient_card.userId"
        [items] = "data.medical_informs.medical_history"
      ></app-edit-list>

      <app-edit-list
        [mode] = "2"
        [userid] = "data.patient_card.userId"
        [items] = "data.medical_informs.medical_treatments"
      ></app-edit-list>

      <app-edit-list
      [mode] = "3"
      [userid] = "data.patient_card.userId"
      [items] = "data.medical_informs.vaccinations"
    ></app-edit-list>

    </div>
    <div class="col-lg-6">

      <app-edit-list
        [mode] = "4"
        [userid] = "data.patient_card.userId"
        [items] = "data.medical_informs.addictions"
      ></app-edit-list>

      <app-edit-list
        [mode] = "5"
        [userid] = "data.patient_card.userId"
        [items] = "data.medical_informs.contraindications"
      ></app-edit-list>

      <app-edit-list
        [mode] = "6"
        [userid] = "data.patient_card.userId"
        [items] = "data.medical_informs.sensitization"
      ></app-edit-list>
    
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Zamknij</button>
</mat-dialog-actions>
