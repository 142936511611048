import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NewUserDataDto } from '@app/dto/new-user-data.dto';
import { SnackService } from '@app/services/snack.service';

import { AuthService, ConfirmedValidator } from './../auth/auth.service';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  private formSubmitAttempt!: boolean;
  private maxLength: number = 100;

  public complete: boolean = false;
  public username: string = "";

  form: FormGroup = this.fb.group({
    //username: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    surname: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    firstname: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    email: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength), Validators.email])],
    telefon: ['', Validators.compose([Validators.maxLength(this.maxLength)])],
    password: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    confirm: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    //dateBirth: [null, Validators.compose([Validators.required])],
    //sex: [null, Validators.required],
    //tall: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(299)])],
    //weight: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(699)])]
}, { validators: ConfirmedValidator('password', 'confirm') });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackService: SnackService
  ) {}

  ngOnInit() {

  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field)?.valid && this.form.get(field)?.touched) ||
      (this.form.get(field)?.untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      let newUser = this.form.value as NewUserDataDto;

      delete newUser.confirm;

      this.authService.register(newUser)
      .subscribe(
        (data: string) => {
          this.snackService.success("Zarejestrowano użytkownika")
          this.username = data.substring(10);
          this.complete = true;

        },
        error => {
          if (error.status == 409) {
            this.snackService.error("Podany adres e-mail lub numer telefonu jest zajęty");
          }
          else {
            this.snackService.error(error.message);
          }
        }
      )
    }
    this.formSubmitAttempt = true;
  }
}