<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <mat-card>
    <mat-card-content>
      <mat-label>Poprzednie hasło</mat-label>
      <mat-form-field class="w-100" appearance="fill">
        <input matInput formControlName="oldPass" required>
      </mat-form-field>

      <mat-label>Nowe hasło</mat-label>
      <mat-form-field class="w-100" appearance="fill">
        <input matInput formControlName="newPass" required>
      </mat-form-field>

      <mat-label>Potwierdź hasło</mat-label>
      <mat-form-field class="w-100" appearance="fill">
        <input matInput formControlName="conPass" required>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" type="submit" [disabled]="form.invalid">Wyślij</button>
    </mat-card-actions>
  </mat-card>


  </form>
  