import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientCardResponseDto } from '@app/dto/patient-card/patient-card-response.dto';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contraindication-dialog',
  templateUrl: './contraindications-dialog.component.html'
})
export class ContraindicationsDialogComponent implements OnInit {
    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: PatientCardResponseDto) { }
  
    eventsSubject: Subject<void> = new Subject<void>();
  
    emitAddContact() {
      this.eventsSubject.next();
    }
  
    openDialog() {
  
    }
  
    ngOnInit(): void {
  
    }
  
  
  }
  