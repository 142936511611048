<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Szczepienie</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Szczepienie</mat-label>
          <textarea matInput formControlName="vaccinations" required></textarea>
          <mat-error *ngIf="dialogForm.controls['vaccinations'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Opis</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Data</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateVaccinations" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="dialogForm.controls['dateVaccinations'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>ID certyfikatu</mat-label>
          <input matInput formControlName="certificateId">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" type="submit">Zapisz</button>
  </div>
  </form>
  