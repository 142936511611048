import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
import { User } from '@app/auth/user';
import { PowiadomDialogComponent } from '@app/patient-card/dialogs/powiadom-dialog/powiadom-dialog.component';
import { SnackService } from '@app/services/snack.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [`
    .grid-container {
      margin: 20px;
    }
    
    .dashboard-card {
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
    }

    .mat-card-avatar * {
      font-size: 40px;
      height: 100%;
      width: 100%;
    }
    
    .more-button {
      position: absolute;
      top: 5px;
      right: 10px;
    }
    
    .dashboard-card-content {
      text-align: center;
    }
    
  `]
})


export class HomeComponent implements OnInit {


  public cards: any = [];

  form!: FormGroup;
  private formSubmitAttempt!: boolean;
  private returnUrl: string = "/";
  username: string|null = null;
  ready: boolean = false;

  public islog?: User|null;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    // private route: ActivatedRoute,
    // private router: Router,
    public dialog: MatDialog,
    private snackService: SnackService
    ) {

      this.form = this.fb.group({
        kod: [this.username, Validators.required],
        pin: ['', Validators.required]
      });
      
    this.authService.currentUser.subscribe(x => {
      this.islog = x;
      this.loadCards();
    });
  }

  ngOnInit() {
    this.form.get('pin')!.valueChanges.
    pipe(
      distinctUntilChanged(),
      debounceTime(500)
    )
    .subscribe(value => {
        this.onSubmit(this.form.value.kod, value);
   })
  }


  pressEnter() {
    this.onSubmit(this.form.value.kod, this.form.value.pin);
  }


  onSubmit(kod: string, pin: string) {
    if (this.form.valid && pin.length == 4) {
      this.authService.loginAnonim(kod, pin)
      .subscribe(
        data => {
          if (this.authService.checkToken(data)) {
            this.authService.signIn(data);
            this.snackService.success("Zalogowano");
            //this.router.navigate([this.returnUrl]);
          }
          else {
            // console.log('zly token');
            this.snackService.error("Nieoczekiwany błąd");
          }
        },
        error => {
          if (error.status == 401) {
            this.snackService.error("Niepoprawny login lub PIN");
          }
          else {
            this.snackService.error(error.message);
          }
        }
      )
    }
    this.formSubmitAttempt = true;
  }

  loadCards(): void {
    if (this.islog) {
      if (this.islog.role == "ROLE_USER") {
        this.cards = userCards;
      }
      else {
        this.cards = anonimCards;
      }
    }
    else {
      this.cards = logoutCards;
    }
  }

  powiadom() {
    const dialogRef = this.dialog.open(PowiadomDialogComponent, {
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }
}

const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

const userCards = [
  {
    title: "Karta pacjenta",
    subtitle: "Zobacz swoją kartę pacjenta",
    content: loremIpsum,
    icon: "medic_card",
    link: "/patient-card",
    class: "offset-lg-2",
    color: "green"
  },
  {
    title: "Ustawienia",
    subtitle: "Edytuj swój profil i uprawnienia",
    content: loremIpsum,
    icon: "settings",
    link: "/settings",
    color: "purple"
  }
];

const anonimCards = [
  {
    title: "Karta pacjenta",
    subtitle: "Zobacz kartę pacjenta",
    content: loremIpsum,
    icon: "medic_card",
    link: "/patient-card",
    class: "offset-sm-3 offset-lg-4",
    color: "green"
  }
];

const logoutCards = [
  {
    title: "Zaloguj",
    subtitle: "Zaloguj się do swojego konta",
    content: loremIpsum,
    icon: "profile",
    link: "/login",
    class: "offset-lg-2",
    color: "blue"
  },
  {
    title: "Zarejestruj",
    subtitle: "Zarejestruj nowe konto",
    content: loremIpsum,
    icon: "invite",
    link: "/register",
    color: "red"
  }
];



