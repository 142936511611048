<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Osoba kontaktowa</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Imię</mat-label>
          <input type="text" matInput formControlName="doctorsName" required>
          <mat-error *ngIf="dialogForm.controls['doctorsName'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nazwisko</mat-label>
          <input type="text" matInput formControlName="doctorsSurname" required>
          <mat-error *ngIf="dialogForm.controls['doctorsSurname'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Tytuł naukowy</mat-label>
          <input type="text" matInput formControlName="academicTitle" required>
          <mat-error *ngIf="dialogForm.controls['academicTitle'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Specjalizacja</mat-label>
          <input type="text" matInput formControlName="specialization">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Numer PWZ</mat-label>
          <input type="text" matInput formControlName="lawNumperProfession">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Telefon</mat-label>
          <input type="text" matInput formControlName="phone">
        </mat-form-field>
      </div>
    </div>


    <mat-checkbox class="overflow-hidden" formControlName="confirmed">Potwierdzony</mat-checkbox>

  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" type="submit">Zapisz</button>
  </div>
  </form>
  