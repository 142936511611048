
  <div class="row">
    <div class="mb-4" [ngClass]="revers ? 'col-md-6 ' : 'col-md-8 offset-md-2'">
      <div class="medical-card mat-elevation-z8">
        <div class="medical-card-content">
          <div class="medical-card-header">
            <div>Karta Medyczna I.C.E.</div>
            <div *ngIf="revers">Kody QR na odwrocie</div>
          </div>
          <div class="row">
            <div class="col-9 medical-card-awers">

                <div>Imię i nazwisko:&nbsp;<span>{{ user.firstname }} {{ user.surname }}</span></div>
                <div>Płeć / Wzrost:&nbsp;<span>{{ patientCard.sex == "M" ? "Mężczyzna" : (patientCard.sex == "K" ? "Kobieta" : "") }} / {{ patientCard.height }}</span></div>
                <div>Grupa krwi:&nbsp;<span>{{ patientCard.bloodGroup }}</span></div>
                <!-- <div>Wzrost / Waga:&nbsp;<span>{{ patientCard.height }} / {{ patientCard.weight }}</span></div> -->
                <div>Choroby:&nbsp;<span><ng-container *ngFor="let item of diseasesManager; let first=first">
                  <ng-container *ngIf="!first">, </ng-container>{{ item.diseases }}</ng-container>
                </span></div>

              <ng-container *ngIf="revers; else cardButton">
                <div *ngIf="contactPersons.length > 0">Osoby kontaktowe:&nbsp;<span><ng-container *ngFor="let item of contactPersons; let first=first">
                  <ng-container *ngIf="!first">, </ng-container>{{ item.kinship }}: {{ item.phone }}</ng-container>
                </span></div>
                <div *ngIf="user.username">Username / PIN:&nbsp;<span>{{ user.username }} / {{ user.pin_card }}</span></div>
              </ng-container>
              <ng-template #cardButton>
                <div *ngIf="loggedUser" class="d-flex align-items-center justify-content-around flex-wrap" [style.height.%]="50">
                  <button mat-raised-button class="mb-2" color="primary" type="button" [routerLink]="['/','patient-card']">
                    Karta pacjenta
                    <mat-icon class="ms-1" [style.marginTop.px]="-4">ads_click</mat-icon>
                  </button>

                  <button mat-raised-button class="mb-2 ms-2" color="warn" type="button" (click)="onPowiadom.emit()">
                    Powiadom rodzinę
                    <mat-icon class="ms-1" [style.marginTop.px]="-4">ads_click</mat-icon>
                  </button>
                </div>
              </ng-template>
            </div>
            <div class="col-3 text-center">
              <div class="medical-card-photo">
                <img *ngIf="photoUrl" [src]="photoUrl">
              </div>
              <img class="medical-card-nfc-a" src="../../assets/images/nfc-logo.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4" *ngIf="revers">
      <div class="medical-card mat-elevation-z8">
        <div class="medical-card-content">
          <div class="medical-card-header">
            <div>Karta Medyczna I.C.E.</div>
            <div>Użyj swojego smartfona w celu zeskanowania poniższych kodów QR</div>
          </div>
          <div class="row">
            <div class="col-4 text-center">
              <div class="medical-card-qr">
                <ng-container *ngIf="qrUrl" >
                  <span>URL</span>
                  <img  [src]="qrUrl">
                </ng-container>
              </div>
            </div>
            <div class="col-4 text-center">
              <img class="medical-card-nfc-r" src="../../assets/images/nfc-logo.svg">
            </div>
            <div class="col-4 text-center">
              <div class="medical-card-qr">
                <ng-container *ngIf="qrSms">
                  <img  [src]="qrSms">
                  <span>SMS</span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 medical-card-awers">
              <ng-container>
                <div *ngIf="attachedDevices.length > 0">Urządzenia:&nbsp;<span><ng-container *ngFor="let item of attachedDevices; let first=first">
                  <ng-container *ngIf="!first">, </ng-container>{{ item.devices }}</ng-container>
                </span></div>
                <div *ngIf="contraindications.length > 0">Przeciwskazania:&nbsp;<span><ng-container *ngFor="let item of contraindications; let first=first">
                  <ng-container *ngIf="!first">, </ng-container>{{ item.contraindications }}</ng-container>
                </span></div>
                <div *ngIf="sensitization.length > 0">Uczulenia:&nbsp;<span><ng-container *ngFor="let item of sensitization; let first=first">
                  <ng-container *ngIf="!first">, </ng-container>{{ item.sitization }}</ng-container>
                </span></div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>