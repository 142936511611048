
  <div mat-dialog-content>

    <p>Aby umożliwić zapisanie danych wpisz poniżej pin otrzymany za pomocą SMS.</p>
    
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>PIN</mat-label>
          <input type="text" matInput [formControl]="formControl">
          <mat-error *ngIf="formControl.hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
    </div>


  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" (click)="onSubmit()">Wyślij</button>
  </div>