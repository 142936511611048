<div class="row" *ngIf="registerData">
  <div class="col-lg-6 offset-lg-3">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h1>Dane użytkownika</h1>

        <h4>W celu dokończenia procesu rejestracji prosimy o wypełnienie poniższych danych:</h4>

        <div class="row mb-3">

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput placeholder="Imię" formControlName="firstname" required readonly>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput placeholder="Nazwisko" formControlName="surname" required readonly>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="email" placeholder="E-mail" formControlName="email" required readonly>
              <mat-error *ngIf="form.controls['email'].errors?.email">Wprowadź poprawny adres e-mail</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="tel" placeholder="Numer telefonu" formControlName="telephone" required readonly>
            </mat-form-field>
          </div>
          




          <div class="col-sm-6">
            
            <mat-form-field class="w-100">
              <mat-label>Data urodzenia</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="dateBirth" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <mat-label>Płeć</mat-label>
              <mat-select formControlName="sex" required>
                <mat-option value="K">Kobieta</mat-option>
                <mat-option value="M">Mężczyzna</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="number" min="0" placeholder="Wzrost" formControlName="height" required>
              <mat-error *ngIf="form.controls['height'].errors?.max">Podawana wartość jest zbyt duża</mat-error>
              <mat-error *ngIf="form.controls['height'].errors?.min">Wartość nie może być ujemna</mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="number" min="0" placeholder="Waga" formControlName="weight" required>
              <mat-error *ngIf="form.controls['weight'].errors?.max">Podawana wartość jest zbyt duża</mat-error>
              <mat-error *ngIf="form.controls['weight'].errors?.min">Wartość nie może być ujemna</mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <mat-label>Grupa krwi</mat-label>
              <mat-select formControlName="bloodGroup" required>
                <mat-option *ngFor="let group of bloodGroups" [value]="group">
                  {{ group }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <mat-label>Zgoda na pobranie narządów</mat-label>
              <mat-select formControlName="approvalsOrganTransplant" required>
                <mat-option [value]="true">Zgadzam się</mat-option>
                <mat-option [value]="false">Nie zgadzam się</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="text" min="0" placeholder="PESEL" formControlName="pesel" required>
              <mat-error *ngIf="form.controls['pesel'].errors?.pattern">Podaj poprawny numer PESEL</mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="text" min="0" placeholder="PIN do karty medycznej" formControlName="pin" required>
              <mat-error *ngIf="form.controls['pin'].errors?.pattern">PIN powinien składać się z od 3 do 10 cyfr</mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="text" placeholder="Miejscowość" formControlName="city" required>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="text" placeholder="Kod pocztowy" formControlName="zipCode" required>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="text" placeholder="Ulica" formControlName="street" required>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="w-100">
              <input matInput type="text" placeholder="Numer domu/mieszkania" formControlName="houseNumber" required>
            </mat-form-field>
          </div>



        </div>

        <button mat-raised-button color="primary" type="submit">Zapisz</button>
      </form>
  </div>
</div>

