<div class="px-4 px-lg-6 mb-5">
  <div class="u-position--relative">
        <h2 class="mb-3">Karta pacjenta</h2>
        <!-- <button class="sms-button" *ngIf="isAnonim" mat-raised-button color="accent" (click)="openSmsDialog()">SMS</button> -->
        <button class="sms-button" *ngIf="isAnonim" mat-button [matMenuTriggerFor]="menuSMS">PIN SMS</button>
        <mat-menu #menuSMS="matMenu">
          <button mat-menu-item (click)="openSmsDialog()">PIN SMS do właściciela karty</button>
          <button mat-menu-item (click)="openRatownikDialog()">PIN SMS do RATOWINKA</button>
        </mat-menu>
        
    <!-- <app-blocker [isVisible]="block"></app-blocker> -->

    <app-first-aid></app-first-aid>

    <div *ngIf="card.patient_card">

      <div class="row">
        <div class="col">
          <mat-card class="h-100">
            <h6 class="mb-3">{{ userData.firstname }} {{ userData.surname }}</h6>
          <div class="card-info">
            <div>
            <div class="image editable mb-2">
              <img [src]="userPhoto" *ngIf="userPhoto; else photoElse"/>
              <ng-template #photoElse>
                <mat-icon>photo_camera</mat-icon>
              </ng-template>
              <button mat-mini-fab class="edit-button" color="accent" (click)="openPhotoEditDialog()" *ngIf="canEdit('PatientCardPhoto')">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
            <p>#{{ card.patient_card.userId }}</p>
            </div>
            <div class="info editable ms-4">
              
              <!-- <p class="blur"><label>{{card.patient_card.sex == 'K' ? 'Janina Kowalska' : 'Jan Kowalski' }}</label></p> -->
              <div>
                <div class="row info-row">
                  <div class="col">
                    <label>Płeć:</label>
                    <p>{{ card.patient_card.sex == 'K' ? 'Kobieta' : 'Mężczyzna' }}</p>
                  </div>
                  <div class="col"><label>Wiek:</label><p>{{ card.patient_card.age }} lat(a)</p></div>
                  <div class="col"><label>Wzrost:</label><p>{{ card.patient_card.height }} cm</p></div>
                  <div class="col"><label>Waga:</label><p>{{ card.patient_card.weight }} kg</p></div>
                  <div class="col"><label>Grupa krwi:</label><p>{{ card.patient_card.bloodGroup }}</p></div>
    
                </div>
    
                <div class="info-row">
                  <label>Zgoda na pobranie narządów:</label>
                  <p>{{ card.patient_card.approvalsOrganTransplant ? 'TAK' : 'NIE' }}</p>
                </div> 
                    
    
                <div *ngIf="canPreview('PatientCardContactPersons')" class="info-row editable">
                  <p>Osoby do kontaktu:</p>
                  <div class="row">
                    <ng-container *ngFor="let item of card.medical_informs.contact_persons">
                      <div *ngIf="item.confirmed" class="col">
                        <label>{{ item.kinship }}</label>
                        <p>{{ item.phone }}</p>
                      </div>
                    </ng-container>
                  </div>
                  <button mat-mini-fab class="edit-button" color="accent" (click)="openContactsEditDialog()" *ngIf="canPreview('PatientCardContactPersons') && canEdit('PatientCardContactPersons')">
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
             
    
              <button mat-mini-fab class="edit-button top-button" color="accent" (click)="openCardEditDialog()" *ngIf="canEdit('PatientCard')">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
    
          </div>
    
        </mat-card>
        </div>

        <div class="col-doctors mt-4 mt-xl-0" *ngIf="canPreview('PatientCardContactPersons')">
          <mat-card class="h-100 p-4">
          <div class="editable card-panel">
            <div>
              <h6 class="mb-4">Lekarze prowadzący</h6>
            </div>
            <div class="row">
                <div class="col col-xl-12" *ngFor="let element of card.medical_informs.attending_doctors; let index=index;">
                  <hr *ngIf="index > 0" class="d-none d-xl-block"/>
                  <div class="doctor-item">
                    <div class="doctor-item--icon">
                      <svg-icon name="stethoscope"></svg-icon>
                    </div>
                    <div class="doctor-item--content">
                      <p><ng-container *ngIf="element.academicTitle">{{ element.academicTitle }} </ng-container>{{ element.doctorsName }} {{ element.doctorsSurname }}</p>
                      <p>{{ element.specialization }}, {{ element.phone }}</p>
                    </div>
                  </div>
                </div>
          </div>
                

            <button mat-mini-fab class="edit-button" color="accent" (click)="openDoctorsEditDialog()" *ngIf="canEdit('PatientCardAttendingDoctors')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-card>
        </div>

      </div>

      <div class="row">
        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardExistingDiseases')">
          <mat-card class="p-0">
            <div class="editable card-panel">
              <div class="p-4"><h6 class="m-0">Rozpoznania</h6></div>
              <table class="w-100 table-medical">
                <thead>
                  <tr>
                    <th>Choroba</th><th>Uwagi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let element of card.medical_informs.existing_diseases; let i = index">
                    <td>{{ element.diseases }}</td>
                    <td>{{ element.description }}</td>
                  </tr>
                </tbody>
              </table>
              <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openDiseasesEditDialog()" *ngIf="canEdit('PatientCardExistingDiseases')">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>

        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardMedicationsTaken')">
          
          <mat-card class="p-0">
            <div class="editable card-panel">
              <div class="p-4"><h6 class="m-0">Zażywane leki</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr>
                  <th>Lek</th><th>Moc</th><th>Uwagi</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let element of card.medical_informs.medications_taken; let i = index">
                  <td>{{ element.medications }}</td>
                  <td>{{ element.strength }}</td>
                  <td>{{ element.description }}</td>
                </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openMedicationsEditDialog()" *ngIf="canEdit('PatientCardMedicationsTaken')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
        </div>
        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardAttachedDevices')">

          <mat-card class="p-0">
          <div class="editable card-panel">
            <div class="p-4"><h6 class="m-0">Urządzenia</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr>
                  <th>Urządzenie</th><th>Uwagi</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let element of card.medical_informs.attached_devices; let i = index">
                  <td>{{ element.devices }}</td>
                  <td>{{ element.description }}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openDevicesEditDialog()" *ngIf="canEdit('PatientCardAttachedDevices')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-card>
        </div>


        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardMedicalHistory')">
          <mat-card class="p-0">
            <div class="editable card-panel">
              <div class="p-4"><h6 class="m-0">Przebyte choroby</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr>
                  <th>Choroba</th><th>Data</th><th>Uwagi</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of card.medical_informs.medical_history">
                  <td>{{ item.diseases }}</td>
                  <td>{{ item.dateFrom | date:"MM.yyyy" }}</td>
                  <td>{{ item.description }}</td>
                </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openHistoryEditDialog()" *ngIf="canEdit('PatientCardMedicalHistory')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
        </div>

        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardMedicalTreatments')">
          <mat-card class="p-0">
          <div class="editable card-panel">
            <div class="p-4"><h6 class="m-0">Wykonane zabiegi</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr><th>Zabieg</th><th>Data</th><th>Uwagi</th></tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of card.medical_informs.medical_treatments">
                  <td>{{ item.treatments }}</td>
                  <td>{{ item.dateTreatments | date:"yyyy" }}</td>
                  <td>{{ item.description }}</td>
                  </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openTreatmentsEditDialog()" *ngIf="canEdit('PatientCardMedicalTreatments')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
          
        </div>

        <div class="col-12 mt-4">
          <mat-card class="p-0">
          <div class="editable card-panel">
            <div class="p-4"><h6 class="m-0">Wyniki badań</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr><th>Badanie</th><th>Data</th><th>Ważność</th><th>Podgląd</th></tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of medicalResults">
                  <td>{{ item.nameResults }}</td>
                  <td>{{ item.dateMedicalResults | date:"MM.yyyy" }}</td>
                  <td>{{ item.expirationDate | date:"MM.yyyy" }}</td>
                  <td><button mat-stroked-button (click)="openResultPhoto(item)">zobacz</button></td>
                  </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openResultsEditDialog()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
          
        </div>

        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardVaccinations')">

          <mat-card class="p-0">
          <div class="editable card-panel">
            <div class="p-4"><h6 class="m-0">Szczepienia</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr><th>Szczepionka</th><th>Data</th><th>Uwagi</th></tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of card.medical_informs.vaccinations">
                  <td>{{ item.vaccinations }}</td>
                  <td>{{ item.dateVaccinations | date:"yyyy" }}</td>
                  <td>{{ item.description }}</td>
                </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openVaccinationsEditDialog()" *ngIf="canEdit('PatientCardVaccinations')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
        </div>


        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardAddictions')">
          <mat-card class="p-0">
          <div class="editable card-panel">
            <div class="p-4"><h6 class="m-0">Nałogi</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr><th>Substancje</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span *ngFor="let item of card.medical_informs.addictions; let i = index"><ng-container *ngIf="i>0">,</ng-container> {{ item.addictions }}</span>
                  </td>
              </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openAddictionsEditDialog()" *ngIf="canEdit('PatientCardAddictions')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
        </div>

        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardContraindications')">

          <mat-card class="p-0">
            <div class="editable card-panel">
              <div class="p-4"><h6 class="m-0">Przeciwwskazania</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr><th>Substancje</th><th>Uwagi</th></tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of card.medical_informs.contraindications; let i=index;">
                  <td>{{ item.contraindications }}</td>
                  <td>{{ item.description }}</td>
                </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openContraindicationsEditDialog()" *ngIf="canEdit('PatientCardContraindications')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          </mat-card>
        </div>

        <div class="col-12 mt-4" *ngIf="canPreview('PatientCardSensitization')">
          <mat-card class="p-0">
          <div class="editable card-panel">
            <div class="p-4"><h6 class="m-0">Uczulenia</h6></div>
            <table class="w-100 table-medical">
              <thead>
                <tr><th>Substancje</th><th>Objawy</th></tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of card.medical_informs.sensitization; let i=index;">
                  <td>{{ item.sitization }}</td>
                  <td>{{ item.description }}</td>
                </tr>
              </tbody>
            </table>
            <button mat-mini-fab class="edit-button m-4" color="accent" (click)="openSensitizationsEditDialog()" *ngIf="canEdit('PatientCardSensitization')">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-card>
        </div>

      </div>







    </div>
  </div>
</div>