

import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";


@Injectable()
export class SnackService {

  constructor(private _snackBar: MatSnackBar) {
  }

  success(message: string) {
    this._snackBar.open(message, undefined, {
      duration: 5000,
      panelClass: "bg-success"
    });
  }

  error(message: string) {
    this._snackBar.open(message, undefined, {
      duration: 5000,
      panelClass: "bg-error"
    });
  }

}
