<form [formGroup]="permissionsForm" (ngSubmit)="onSubmit()" *ngIf="permissionsForm">
  <mat-card class="p-0">
    <div>
      <table class="w-100">
        <thead>
          <tr>
            <th>Rola</th>
            <th>Podgląd</th>
            <th>Edycja</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Użytkownik</td>
            <td><input mat-input type="checkbox" formControlName="roleUserPreview"></td>
            <td><input mat-input type="checkbox" formControlName="roleUserEdit"></td>
          </tr>
  
          <tr>
            <td>Lekarz</td>
            <td><input mat-input type="checkbox" formControlName="roleDoctorPreview"></td>
            <td><input mat-input type="checkbox" formControlName="roleDoctorEdit"></td>
          </tr>
  
          <tr>
            <td>Zalogowany SMS</td>
            <td><input mat-input type="checkbox" formControlName="roleUsersmsPreview"></td>
            <td></td>
          </tr>
  
          <tr>
            <td>Anonimowy</td>
            <td><input mat-input type="checkbox" formControlName="roleAnonimPreview"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-card>
  <div class="text-end mt-3">
    <button mat-flat-button color="primary" class="" type="submit">Zapisz</button>
  </div>
</form>




