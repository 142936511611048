<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
<h1 mat-dialog-title>Choroba/schorzenie</h1>
<div mat-dialog-content>

  <div class="row">
    <div class="col-12">
      <mat-form-field class="example-full-width w-100" appearance="outline">
        <mat-label>Wyszukaj (min 3 znaki)</mat-label>
        <input matInput [matAutocomplete]="auto"
          [formControl]="searchControl">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)"
          >
          <mat-option *ngIf="searchLoading" class="is-loading">Ładowanie...</mat-option>
          <ng-container *ngIf="!searchLoading">
            <mat-option *ngFor="let option of filteredDiseases" [value]="option">
              <span><b>{{option.code}}</b> {{option.name}}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Choroba</mat-label>
        <textarea matInput formControlName="diseases" required></textarea>
        <mat-error *ngIf="dialogForm.controls['diseases'].hasError('required')">To pole jest wymagane</mat-error>
      </mat-form-field>
    </div>


    <div class="col-12">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>ICD-10</mat-label>
        <input matInput formControlName="codeIcd10" required>
        <mat-error *ngIf="dialogForm.controls['diseases'].hasError('required')">To pole jest wymagane</mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Opis</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Data</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateFrom" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dialogForm.controls['dateFrom'].hasError('required')">To pole jest wymagane</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Data wyleczenia</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="dateTo">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
  <button mat-flat-button color="primary" type="submit">Zapisz</button>
</div>
</form>
