<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Badanie</h1>
  <div mat-dialog-content>
    
    <div class="row">
      <div class="col-12">
        <mat-form-field class="example-full-width w-100" appearance="outline">
          <mat-label>Wyszukaj (min 3 znaki)</mat-label>
          <input matInput [matAutocomplete]="auto"
            [formControl]="searchControl">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)"
            >
            <mat-option *ngIf="searchLoading" class="is-loading">Ładowanie...</mat-option>
            <ng-container *ngIf="!searchLoading">
              <mat-option *ngFor="let option of filteredResults" [value]="option">
                <span>{{ option.value }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-12 mb-2">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Badanie</mat-label>
          <input matInput formControlName="nameResults" required readonly>
          <mat-hint>Wyszukaj badanie według nazwy</mat-hint>
          <mat-error *ngIf="dialogForm.controls['nameResults'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Data</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateMedicalResults" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="dialogForm.controls['dateMedicalResults'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 mb-2">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Plik</mat-label>
          <ngx-mat-file-input formControlName="file" accept="image/*" placeholder="Wybierz plik">
          </ngx-mat-file-input>
          <mat-error *ngIf="dialogForm.controls['file'].hasError('required')">To pole jest wymagane</mat-error>
          <mat-hint>Dodaj zdjęcie wyników badania</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Opis</mat-label>
          <textarea matInput formControlName="userDescription"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" type="submit">Zapisz</button>
  </div>
  </form>
  