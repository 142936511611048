import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Medkart';

  constructor(private titleService: Title, private iconReg:SvgIconRegistryService) {
    this.titleService.setTitle(this.title);
  }

  public ngOnInit(): void {
    this._registerIcons();
  }

  private _registerIcons() {
    for (const [key, value] of Object.entries(this.icons)) {
      this.iconReg.loadSvg(`assets/svg/medlife/${value}`, key)?.subscribe();
    }
  }

  private readonly icons = {
    add: "Add.svg",
    appointment: "Appointment.svg",
    archive: "Archive.svg",
    arrow: "Arrow.svg",
    arrow_down: "Arrow alt.svg",
    arrow_right: "Arrow-1.svg",
    calendar: "Calendar.svg",
    calendar_small: "Calendar-1.svg",
    call: "Call.svg",
    chat: "Chat.svg",
    check: "Check.svg",
    decline: "Decline.svg",
    document: "Document.svg",
    drug_base: "Drug base.svg",
    edit: "Edit.svg",
    filters: "Filters.svg",
    folder: "Folder.svg",
    full_screen: "Full screen.svg",
    heart: "Heart.svg",
    home: "Home.svg",
    info: "Info.svg",
    logout: "Logout.svg",
    medic_card: "Medic card.svg",
    menu_dot: "Menu dot.svg",
    minimize: "Minimize.svg",
    mute: "Mute.svg",
    no_video: "No video.svg",
    patients: "Patients.svg",
    search: "Search.svg",
    settings: "Settings.svg",
    stethoscope: "Stethoscope.svg",
    video: "Video.svg",
    view: "View.svg",
    voice: "Voice.svg",
    bell_unactive: "Bell/Unactive.svg",
    bell_active: "Bell/Active.svg",
    clock_small: "Clock/Small.svg",
    clock_medium: "Clock/Medium.svg",
    clock_big: "Clock/Big.svg",
    clinic: "Clinic.svg",
    support: "Support.svg",
    arrow_prev: "Arrow prev.svg",
    arrow_next: "Arrow next.svg",
    invite: "Invite.svg",
    profile: "Profile.svg",
    projects: "Projects.svg"
  };
}
