<p>Uprawnienia do przeglądania i edytowania poszczególnych informacji:</p>

<div class="row">

  <div class="col-md-4 mb-3">
    <app-selection-list [options]="listOptions" #parts></app-selection-list>
  </div>

  <div class="col-md-8 mb-3">

    <div [hidden]="!(parts.selected===0)">
      <app-settings-permissions [permissions]="getPermissions('PatientCard')"></app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===1)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardExistingDiseases')">
      </app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===2)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardMedicalHistory')"></app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===3)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardMedicalTreatments')">
      </app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===4)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardVaccinations')"></app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===5)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardSensitization')"></app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===6)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardContraindications')">
      </app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===7)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardAddictions')"></app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===8)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardContactPersons')"></app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===9)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardAttendingDoctors')">
      </app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===10)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardMedicationsTaken')">
      </app-settings-permissions>
    </div>
    <div [hidden]="!(parts.selected===11)">
      <app-settings-permissions [permissions]="getPermissions('PatientCardAttachedDevices')"></app-settings-permissions>
    </div>

  </div>
</div>
