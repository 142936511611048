import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExistingDiseaseDto } from '@app/dto/patient-card/existing-disease.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-existing-disease-edit-dialog',
  templateUrl: './existing-disease-edit-dialog.component.html'
})
export class ExistingDiseaseEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    diseases: new FormControl(this.data?.diseases, Validators.required),
    dateFrom: new FormControl(this.data?.dateFrom, Validators.required),
    description: new FormControl(this.data?.description),
    codeIcd10: new FormControl(this.data?.codeIcd10, Validators.required)
  });


  constructor(
    public dialogRef: MatDialogRef<ExistingDiseaseEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: ExistingDiseaseDto,
    private cardService: PatientCardService,
    private snackService: SnackService) {
  }


  searchControl = new FormControl(null);
  filteredDiseases: any;
  searchLoading = false;

  onSelected(event: any) {
    this.searchControl.setValue(null);
    this.dialogForm.controls['diseases'].setValue(event.option.value.name);
    this.dialogForm.controls['codeIcd10'].setValue(event.option.value.code);
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.filteredDiseases = [];
          this.searchLoading = true;
        }),
        switchMap(value => this.cardService.authIcd10ByKeyword(value))
      )
      .subscribe(
        (data: any) => {
        if (data == undefined) {
          this.filteredDiseases = [];
        } else {
          this.filteredDiseases = data;
        }
        this.searchLoading = false;
      },
      error => {
        this.searchLoading = false;
      });
  }



  onSubmit() {

    // console.log(this.dialogForm.errors);

    if (this.dialogForm.invalid)
      return;

    const item: ExistingDiseaseDto = this.dialogForm.value;

    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: ExistingDiseaseDto) {
    this.cardService.authAddExistingDisease(item)
      .subscribe(
        data => {
          this.snackService.success("Dodano element");
          this.dialogRef.close(data);
        },
        error => {
          this.snackService.error(error.message);
        }
      );
  }

  updateItem(item: ExistingDiseaseDto) {
    this.cardService.authUpdateExistingDisease(item)
      .subscribe(
        data => {
          this.snackService.success("Zaktualizowano element");
          this.dialogRef.close(data);
        },
        error => {
          this.snackService.error(error.message);
        }
      );
  }

}
