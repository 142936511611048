import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService, PermissionString } from '@app/auth/auth.service';
import { User } from '@app/auth/user';
import { AttachedDeviceDto } from '@app/dto/patient-card/attached-device.dto';
import { MedicalInformsDto } from '@app/dto/patient-card/medical-informs.dto';
import { MedicationTakenDto } from '@app/dto/patient-card/medication-taken.dto';
import { PatientCardResponseDto } from '@app/dto/patient-card/patient-card-response.dto';
import { PatientCardDto } from '@app/dto/patient-card/patient-card.dto';
import { UserDataDto } from '@app/dto/user-data.dto';
import { UserPermissionDto } from '@app/dto/user-permission.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { AddictionsDialogComponent } from './dialogs/addictions-dialog/addictions-dialog.component';
import { AttachedDevicesDialogComponent } from './dialogs/attached-devices-dialog/attached-devices-dialog.component';
// import { ConfirmDialogComponent } from '@app/shared/components/dialogs/confirm/confirm-dialog.component';
// import { ToastService } from '@app/shared/services/toast.service';
import { AttendingDoctorsDialogComponent } from './dialogs/attending-doctors-dialog/attending-doctors-dialog.component';
import { ContactPersonsDialogComponent } from './dialogs/contact-persons-dialog/contact-persons-dialog.component';
import { ContraindicationsDialogComponent } from './dialogs/contraindications-dialog/contraindications-dialog.component';
import { DeleteConfirmDialogComponent } from './dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { ExistingDiseaseEditDialogComponent } from './dialogs/existing-disease-edit-dialog/existing-disease-edit-dialog.component';
import { ExistingDiseasesDialogComponent } from './dialogs/existing-diseases-dialog/existing-diseases-dialog.component';
import { MedicalHistoryDialogComponent } from './dialogs/medical-history-dialog/medical-history-dialog.component';
import { MedicalInfromsDialogComponent } from './dialogs/medical-infroms-dialog/medical-infroms-dialog.component';
import { MedicalTreatmentsDialogComponent } from './dialogs/medical-treatments-dialog/medical-treatments-dialog.component';
import { MedicationsTakenDialogComponent } from './dialogs/medications-taken-dialog/medications-taken-dialog.component';
import { PatientCardDialogComponent } from './dialogs/patient-card-dialog/patient-card-dialog.component';
import { PhoneDialogComponent } from './dialogs/phone-dialog/phone-dialog.component';
import { PhotoDialogComponent } from './dialogs/photo-dialog/photo-dialog.component';
import { SensitizationsDialogComponent } from './dialogs/sensitizations-dialog/sensitizations-dialog.component';
import { SmsDialogComponent } from './dialogs/sms-dialog/sms-dialog.component';
import { VaccinationsDialogComponent } from './dialogs/vaccinations-dialog/vaccinations-dialog.component';
import { MedicalResultDto } from '@app/dto/medical-result.dto';
import { MedicalResultsDialogComponent } from './dialogs/medical-results-dialog/medical-results-dialog.component';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';


export interface TableData {
  disease: string;
  medications: MedicationTakenDto[];
  devices: AttachedDeviceDto[];
}

@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss']
})
export class PatientCardComponent implements OnInit {


  public medicalResults: MedicalResultDto[] = [];

  public card: PatientCardResponseDto = {} as PatientCardResponseDto;
  public userData: UserDataDto = {} as UserDataDto;
  public id: number = 2131;
  public block: boolean = true;
  public userPhoto?: SafeUrl;
  private sentSMS: boolean = false;
  // public tableData: TableData[] = [];
  // public displayedColumns: string[] = ['id', 'diseases', 'medications'];

  // public elements: string[] = ["pierwszy", "drugi", "trzeci", "czwarty", "piąty", "szósty"];

  islog?: User|null;

  get isUser() {
    return this.islog?.role == "ROLE_USER";
  }

  get isAnonim() {
    return this.islog?.role == "ROLE_ANONIM";
  }
  
  constructor(
    private lightbox: Lightbox,
    private config: LightboxConfig,
    private readonly patientCardService: PatientCardService,
    //private readonly toast: ToastService,
    private sanitizer: DomSanitizer,
    private readonly snackService: SnackService,
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.currentUser.subscribe(x => this.islog = x);
  }


  canEdit(permission: PermissionString): boolean {
    return this.authService.hasPermission(permission, "edit");
  }

  canPreview(permission: PermissionString): boolean {
    return this.authService.hasPermission(permission, "preview");
  }


  ngOnInit(): void {

    this.patientCardService.medicalResults().subscribe(
      data => {
        this.medicalResults = data;
       },
      error => { console.log(error); }
    )

    this.patientCardService.authUserData()
    .subscribe(
      data => {
        if (data !== null) {
          if (data.pin == null) {
            this.router.navigate(['register-data']);
            return;
          }
          this.userData = data;
        }
      },
      error => {
        // this.toast.error(error.message);
        this.snackService.error(error.message);
      }
    );

    
    this.patientCardService.getPatientCardByRole(this.islog?.role || '')
    .subscribe(
      data => {
        if (data !== null) {
          this.card = data
          this.loadPhoto();
        }
        this.block = false;
      },
      error => {
        // this.toast.error(error.message);
        this.snackService.error(error.message);
        this.block = false;
      }
    );
  }


  openSmsDialog() {

    if (!this.sentSMS) {
      this.authService.sendSMS(this.userData.username)
      .subscribe(
        data => {
          this.sentSMS = true;
        },
        error => {
            this.snackService.error(error.message);
        }
      )
    }

    const dialogRef = this.dialog.open(SmsDialogComponent, {
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  openRatownikDialog() {

    // this.authService.sendSMS(this.userData.username)
    // .subscribe(
    //   data => {
    //     this.sentSMS = true;
    //   },
    //   error => {
    //       this.snackService.error(error.message);
    //   }
    // )

    const dialogRef = this.dialog.open(PhoneDialogComponent, {
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
  

  loadPhoto() {
    this.patientCardService.authGetPhoto().subscribe(
      data => {
        // console.log(data);
        if (data != null && data.size > 0) {
          
        const objectURL = URL.createObjectURL(data);
        const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        // console.log(img);
        this.userPhoto = img;
        }
      },
      error => {
        // console.log(error.message);
      }
    );
  }

  openPhotoEditDialog() {
    const dialogRef = this.dialog.open(PhotoDialogComponent, {
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userPhoto = undefined;
        this.loadPhoto();
      }
    });
  }

  openContactsEditDialog() {
    const dialogRef = this.dialog.open(ContactPersonsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openCardEditDialog() {
    const dialogRef = this.dialog.open(PatientCardDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  // openInformsEditDialog() {
  //   const dialogRef = this.dialog.open(MedicalInfromsDialogComponent, {
  //     data: this.card,
  //     maxWidth: '100vw',
  //     panelClass: 'dialog-lg'
  //   });
  // }

  openDoctorsEditDialog() {
    const dialogRef = this.dialog.open(AttendingDoctorsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openDiseasesEditDialog() {
    const dialogRef = this.dialog.open(ExistingDiseasesDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  // ..................

  openMedicationsEditDialog() {
    const dialogRef = this.dialog.open(MedicationsTakenDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openDevicesEditDialog() {
    const dialogRef = this.dialog.open(AttachedDevicesDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openHistoryEditDialog() {
    const dialogRef = this.dialog.open(MedicalHistoryDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openTreatmentsEditDialog() {
    const dialogRef = this.dialog.open(MedicalTreatmentsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openResultsEditDialog() {
    const dialogRef = this.dialog.open(MedicalResultsDialogComponent, {
      data: this.medicalResults,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openAddictionsEditDialog() {
    const dialogRef = this.dialog.open(AddictionsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openVaccinationsEditDialog() {
    const dialogRef = this.dialog.open(VaccinationsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openContraindicationsEditDialog() {
    const dialogRef = this.dialog.open(ContraindicationsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }

  openSensitizationsEditDialog() {
    const dialogRef = this.dialog.open(SensitizationsDialogComponent, {
      data: this.card,
      maxWidth: '100vw',
      panelClass: 'dialog-md'
    });
  }


  openResultPhoto(result: MedicalResultDto) {
    let album: any[] = [];

    this.patientCardService.getFileResult(result.idFile).subscribe(
      data => {
        album.push({
          src: data,
          thumb: data,
          caption: result.userDescription
        });
        this.lightbox.open(album, 0);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
