<h2 mat-dialog-title>Istniejące choroby</h2>



      <app-edit-list
        [mode] = "9"
        [single] = "true"
        [userid] = "data.patient_card.userId"
        [items] = "data.medical_informs.existing_diseases"
        [events]="eventsSubject.asObservable()"
      ></app-edit-list>
    

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close cdkFocusInitial>Zamknij</button>
  <button mat-flat-button (click)="emitAddContact()" color="primary">Dodaj</button>
</mat-dialog-actions>
