import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalHistoryDto } from '@app/dto/patient-card/medical-history.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-medical-disease-edit-dialog',
  templateUrl: './medical-disease-edit-dialog.component.html'
})
export class MedicalDiseaseEditDialogComponent implements OnInit {


  dialogForm = new FormGroup({
    diseases: new FormControl(this.data?.diseases, Validators.required),
    dateFrom: new FormControl(this.data?.dateFrom, Validators.required),
    dateTo: new FormControl(this.data?.dateTo),
    codeIcd10: new FormControl(this.data?.codeIcd10, Validators.required),
    description: new FormControl(this.data?.description)
  });
  
  constructor(
    public dialogRef: MatDialogRef<MedicalDiseaseEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: MedicalHistoryDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  searchControl = new FormControl(null);
  filteredDiseases: any;
  searchLoading = false;

  onSelected(event: any) {
    this.searchControl.setValue(null);
    this.dialogForm.controls['diseases'].setValue(event.option.value.name);
    this.dialogForm.controls['codeIcd10'].setValue(event.option.value.code);
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.filteredDiseases = [];
          this.searchLoading = true;
        }),
        switchMap(value => this.cardService.authIcd10ByKeyword(value))
      )
      .subscribe(
        (data: any) => {
        if (data == undefined) {
          this.filteredDiseases = [];
        } else {
          this.filteredDiseases = data;
        }
        this.searchLoading = false;
      },
      error => {
        this.searchLoading = false;
      });
  }


  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: MedicalHistoryDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: MedicalHistoryDto) {
    this.cardService.authAddMedicalHistory(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: MedicalHistoryDto) {
    this.cardService.authUpdateMedicalHistory(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
