import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NotAuthGuard } from './auth/not-auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginAnonimComponent } from './login-anonim/login-anonim.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { RegisterComponent } from './register/register.component';
import { SettingsComponent } from './settings/settings.component';
import { UserDataComponent } from './register-data/register-data.component';
import { SettingsRegisterComponent } from './settings-register/settings-register.component';
import { MedicalCardComponent } from './medical-card/medical-card.component';
import { SettingsLayoutComponent } from './settings-layout/settings-layout.component';
import { MedicalCardPageComponent } from './medical-card-page/medical-card-page.component';
import { SettingsPasswordComponent } from './settings-password/settings-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordChangeComponent } from './password-change/password-change.component';

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      
      { path: "login", component: LoginComponent, canActivate: [NotAuthGuard] },
      { path: "register", component: RegisterComponent, canActivate: [NotAuthGuard] },
      { path: "reset-password", component: ResetPasswordComponent },
      { path: "password-change/:token", component: PasswordChangeComponent },
      { path: "patient-card/:username/:pin",  component: LoginAnonimComponent },
      { path: "patient-card/:username",  component: LoginAnonimComponent },
      { path: "patient-card",  component: PatientCardComponent, canActivate: [AuthGuard] },
      { path: "my-medical-card",  component: MedicalCardPageComponent, canActivate: [AuthGuard] },
      {
        path: 'settings',
        component: SettingsLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'profile',
            component: SettingsRegisterComponent
          },
          {
            path: 'permissions',
            component: SettingsComponent,
          },
          {
            path: 'password',
            component: SettingsPasswordComponent,
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'profile',
          },
        ],
      },
      { path: "register-data",  component: UserDataComponent, canActivate: [AuthGuard], data: { role: "ROLE_USER" } },
      { path: "", component: HomeComponent },
      { path: '**',  component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
