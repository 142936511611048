import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddictionDto as AddictionDto } from '@app/dto/patient-card/addiction.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-addiction-edit-dialog',
  templateUrl: './addiction-edit-dialog.component.html'
})
export class AddictionEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    addictions: new FormControl(this.data?.addictions, Validators.required),
    dateFrom: new FormControl(this.data?.dateFrom),
    dateToAdd: new FormControl(this.data?.dateToAdd)
  });
  
  constructor(
    public dialogRef: MatDialogRef<AddictionEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: AddictionDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  ngOnInit(): void {
    // console.log(this.data);
  }

  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: AddictionDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: AddictionDto) {
    this.cardService.authAddAddiction(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: AddictionDto) {
    this.cardService.authUpdateAddiction(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
