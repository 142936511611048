import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordDto } from '@app/dto/change-password.dto copy';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styles: [
  ]
})
export class PasswordChangeComponent implements OnInit {

  form = new FormGroup({
    user: new FormControl("", Validators.required),
    pin: new FormControl("", Validators.required),
    newPassword: new FormControl("", Validators.required),
    conPassword: new FormControl("", Validators.required)
  });

  token: string | null = null;

  constructor(private service: PatientCardService,
    private snackService: SnackService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  onSubmit() {

    if (this.form.invalid)
      return;

    const value = this.form.value;

    if (value.newPassword != value.conPassword) {
      this.snackService.error("Hasła nie są takie same");
      return;
    }

    const reqBody: ChangePasswordDto = {
      token: this.token || '',
      username: value.user,
      codeSms: value.pin,
      newPassword: value.newPassword
    };

    this.service.userChangePasswordToken(reqBody)
    .subscribe(
      data => {
        this.snackService.success(data);
        if (data == 'OK') {
          this.router.navigate(['/','login']);
        }
      },
      error => {
        this.snackService.error(error.message);
      }
    );
    
  }

}
