import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MedicalResultDto } from '@app/dto/medical-result.dto';
import { AddictionDto as AddictionDto } from '@app/dto/patient-card/addiction.dto';
import { AttachedDeviceDto } from '@app/dto/patient-card/attached-device.dto';
import { AttendingDoctorDto } from '@app/dto/patient-card/attending-doctor.dto';
import { ContactPersonDto } from '@app/dto/patient-card/contact-person.dto';
import { ContraindicationDto } from '@app/dto/patient-card/contraindication.dto';
import { ExistingDiseaseDto } from '@app/dto/patient-card/existing-disease.dto';
import { MedicalHistoryDto } from '@app/dto/patient-card/medical-history.dto';
import { MedicalTreatmentDto } from '@app/dto/patient-card/medical-treatment.dto';
import { MedicationTakenDto } from '@app/dto/patient-card/medication-taken.dto';
import { SensitizationDto } from '@app/dto/patient-card/sensitization.dto';
import { VaccinationDto } from '@app/dto/patient-card/vaccination.dto';
import { AddictionEditDialogComponent } from '@app/patient-card/dialogs/addiction-edit-dialog/addiction-edit-dialog.component';
import { AttachedDeviceEditDialogComponent } from '@app/patient-card/dialogs/attached-device-edit-dialog/attached-device-edit-dialog.component';
import { ContactPersonEditDialogComponent } from '@app/patient-card/dialogs/contact-person-edit-dialog/contact-person-edit-dialog.component';
import { ContraindicationEditDialogComponent } from '@app/patient-card/dialogs/contraindication-edit-dialog/contraindication-edit-dialog.component';
import { DeleteConfirmDialogComponent } from '@app/patient-card/dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { DoctorEditDialogComponent } from '@app/patient-card/dialogs/doctor-edit-dialog/doctor-edit-dialog.component';
import { ExistingDiseaseEditDialogComponent } from '@app/patient-card/dialogs/existing-disease-edit-dialog/existing-disease-edit-dialog.component';
import { MedicalDiseaseEditDialogComponent } from '@app/patient-card/dialogs/medical-disease-edit-dialog/medical-disease-edit-dialog.component';
import { MedicalResultsEditDialogComponent } from '@app/patient-card/dialogs/medical-results-edit-dialog/medical-results-edit-dialog.component';
import { MedicalTreatmentEditDialogComponent } from '@app/patient-card/dialogs/medical-treatment-edit-dialog/medical-treatment-edit-dialog.component';
import { MedicationTakenEditDialogComponent as MedicationTakenEditDialogComponent } from '@app/patient-card/dialogs/medication-taken-edit-dialog/medication-taken-edit-dialog.component';
import { SensitizationEditDialogComponent } from '@app/patient-card/dialogs/sensitization-edit-dialog/sensitization-edit-dialog.component';
import { VaccinationEditDialogComponent } from '@app/patient-card/dialogs/vaccination-edit-dialog/vaccination-edit-dialog.component';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { Observable, Subscription } from 'rxjs';


export type InformItem = MedicalHistoryDto | MedicalTreatmentDto | VaccinationDto | AddictionDto | ContraindicationDto | SensitizationDto | ContactPersonDto | AttendingDoctorDto | ExistingDiseaseDto | MedicationTakenDto | AttachedDeviceDto | MedicalResultDto;

export enum InformType {
  Undefined = 0,
  MedicalHistory,
  MedicalTreatments,
  Vaccinations,
  Addictions,
  Contraindications,
  Sensitizations,
  ContactPersons,
  AttendingDoctors,
  ExistingDiseases,
  MedicationsTaken,
  AttachedDevices,
  MedicalResults
}

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.scss']
})
export class EditListComponent implements OnInit {

  constructor(public dialog: MatDialog, private cardService: PatientCardService, private snackService: SnackService) { }

  @Input()
  public single: boolean = false;

  @Input()
  public userid: number = 0;
  
  @Input()
  public mode: InformType = 0;
  
  @Input()
  public items: InformItem[] = [];

  private eventsSubscription: Subscription | undefined;

@Input() events: Observable<void> | undefined;

ngOnDestroy() {
  if (this.eventsSubscription != undefined)
  this.eventsSubscription.unsubscribe();
}

  ngOnInit(): void {
    this.eventsSubscription = this.events?.subscribe(() => this.onAdd());
  }

  onDelete(item: InformItem) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let observable;
        switch (this.mode) {
          case 1: observable = this.cardService.authDeleteMedicalHistory(item.id); break;
          case 2: observable = this.cardService.authDeleteMedicalTreatment(item.id); break;
          case 3: observable = this.cardService.authDeleteVaccination(item.id); break;
          case 4: observable = this.cardService.authDeleteAddiction(item.id); break;
          case 5: observable = this.cardService.authDeleteContraindication(item.id); break;
          case 6: observable = this.cardService.authDeleteSensitization(item.id); break;
          case 7: observable = this.cardService.authDeleteContactPerson(item.id); break;
          case 8: observable = this.cardService.authDeleteDoctor(item.id); break;
          case 9: observable = this.cardService.authDeleteExistingDisease(item.id); break;
          case 10: observable = this.cardService.authDeleteMedicationTaken(item.id); break;
          case 11: observable = this.cardService.authDeleteAttachedDevice(item.id); break;
          case 12: observable = this.cardService.deleteMedicalResult(item.id); break;
        }
        if (observable == undefined)
          return;
        observable.subscribe(
          data => {
            this.snackService.success("Usunięto element");
            this.deleteItem(item)
          },
          error => {
            this.snackService.error(error.message);
          }
        );
      }
    });
  }

  onEdit(item: InformItem) {
    let dialogRef;
    switch (this.mode) {
      case 1: dialogRef = this.dialog.open(MedicalDiseaseEditDialogComponent, this.makeOptions(item)); break;
      case 2: dialogRef = this.dialog.open(MedicalTreatmentEditDialogComponent, this.makeOptions(item)); break;
      case 3: dialogRef = this.dialog.open(VaccinationEditDialogComponent, this.makeOptions(item)); break;
      case 4: dialogRef = this.dialog.open(AddictionEditDialogComponent, this.makeOptions(item)); break;
      case 5: dialogRef = this.dialog.open(ContraindicationEditDialogComponent, this.makeOptions(item)); break;
      case 6: dialogRef = this.dialog.open(SensitizationEditDialogComponent, this.makeOptions(item)); break;
      case 7: dialogRef = this.dialog.open(ContactPersonEditDialogComponent, this.makeOptions(item)); break;
      case 8: dialogRef = this.dialog.open(DoctorEditDialogComponent, this.makeOptions(item)); break;
      case 9: dialogRef = this.dialog.open(ExistingDiseaseEditDialogComponent, this.makeOptions(item)); break;
      case 10: dialogRef = this.dialog.open(MedicationTakenEditDialogComponent, this.makeOptions(item)); break;
      case 11: dialogRef = this.dialog.open(AttachedDeviceEditDialogComponent, this.makeOptions(item)); break;
    }
    
    if (dialogRef == undefined)
      return;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateItem(result);
      }
    });
  }

  onAdd() {
    let dialogRef;
    switch (this.mode) {
      case 1: dialogRef = this.dialog.open(MedicalDiseaseEditDialogComponent, this.makeOptions()); break;
      case 2: dialogRef = this.dialog.open(MedicalTreatmentEditDialogComponent, this.makeOptions()); break;
      case 3: dialogRef = this.dialog.open(VaccinationEditDialogComponent, this.makeOptions()); break;
      case 4: dialogRef = this.dialog.open(AddictionEditDialogComponent, this.makeOptions()); break;
      case 5: dialogRef = this.dialog.open(ContraindicationEditDialogComponent, this.makeOptions()); break;
      case 6: dialogRef = this.dialog.open(SensitizationEditDialogComponent, this.makeOptions()); break;
      case 7: dialogRef = this.dialog.open(ContactPersonEditDialogComponent, this.makeOptions()); break;
      case 8: dialogRef = this.dialog.open(DoctorEditDialogComponent, this.makeOptions()); break;
      case 9: dialogRef = this.dialog.open(ExistingDiseaseEditDialogComponent, this.makeOptions()); break;
      case 10: dialogRef = this.dialog.open(MedicationTakenEditDialogComponent, this.makeOptions()); break;
      case 11: dialogRef = this.dialog.open(AttachedDeviceEditDialogComponent, this.makeOptions()); break;
      case 12: dialogRef = this.dialog.open(MedicalResultsEditDialogComponent, this.makeOptions()); break;
    }
    
    if (dialogRef == undefined)
      return;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pushItem(result);
      }
    });
  }

  deleteItem(item: InformItem) {
    let index = this.items.indexOf(item);
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }

  updateItem(item: InformItem) {
    let index = this.items.findIndex(i => i.id == item.id);
    if (index > -1) {
      this.items[index] = item;
    }
  }

  pushItem(item: InformItem) {
    this.items.push(item);
  }

  private makeOptions(data: any = null, panelClass: string = "dialog-md"): MatDialogConfig {
    return {
      data: data != null ? data : { userId: this.userid } as InformItem,
      maxWidth: '100vw',
      panelClass: panelClass
    }
  }

  public title() {
    switch (this.mode) {
      case 1:  return 'Choroby/schorzenia';
      case 2: return 'Zabiegi';
      case 3:  return 'Szczepienia';
      case 4: return 'Nałogi';
      case 5:  return 'Przeciwwskaznia';
      case 6: return 'Uczulenia';
      case 7: return 'Osoby kontaktowe';
      case 8: return 'Lekarze prowadzący';
      case 9: return 'Istniejące choroby';
      case 10: return 'Zażywane leki';
      case 11: return 'Urządzenia';
      case 11: return 'Wyniki badań';
      default: return '';
    }
  }
}


