import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { AuthService } from '@app/auth/auth.service';
import { User } from '@app/auth/user';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-first-aid',
  templateUrl: './first-aid.component.html',
  styleUrls: [
    './first-aid.component.scss'
  ]
})
export class FirstAidComponent implements OnInit {

  @ViewChild(MatAccordion) accordion!: MatAccordion;


  public help: any[] = [];


  constructor(
    private authService: AuthService,
    private snackService: SnackService,
    private cardService: PatientCardService
    ) {
      
    this.authService.currentUser.subscribe(user => {
      this.loadFirstAid(user);
    });
  }

  ngOnInit() {
  }

  loadFirstAid(user: any) {
    this.help = [];

    if (user) {
      this.cardService.authGetMedicalCardAwers().subscribe(
        data => {
          let help = new Set();
          for (let item of data.icd_help) {
            if (item && item.procedureHelp) {
              help.add(item.procedureHelp);
            }
          }
          this.help = [...help];
        },
        error => {

        }
      );
    }
  }


}
