
  <div mat-dialog-content>

    <label><b>Krok 1</b></label>

    <p>Wyślij numer telefonu na który ma zostać przesłany PIN.</p>
    
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex align-items-start">
          <mat-form-field class="flex-grow-1 me-3" appearance="fill">
            <input type="text" matInput [formControl]="formControl">
            <mat-error *ngIf="formControl.invalid">Wpisz poprawny numer telefonu</mat-error>
          </mat-form-field>
          <button mat-flat-button color="primary" (click)="onSend()">Wyślij</button>
        </div>
      </div>
    </div>

    <label><b>Krok 2</b></label>

    <p>Wprowadź poniżej kod otrzymany w wiadomości SMS i zatwierdź, aby uzyskać dostęp do bardziej szczegółowych informacji.</p>
    
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="w-100" appearance="fill">
          <input type="text" matInput [formControl]="formControlPIN">
          <mat-error *ngIf="formControlPIN.hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
    </div>


  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" (click)="onSubmit()">OK</button>
  </div>