import { Component, Input, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  host: {
    class: 'c-selection-list'
  },
  styles: [
  ]
})
export class SelectionListComponent {

  @Input()
  public options: string[] = [];


  public selected: number = 0;

  public selChange(event: MatSelectionListChange) {
    console.log(event.source.selectedOptions.selected[0]?.value);
    this.selected = event.option.value;
  }
  
}
