import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [
  ]
})
export class ResetPasswordComponent implements OnInit {

  form = new FormGroup({
    user: new FormControl("", Validators.required),
    phone: new FormControl("", Validators.required)
  });

  constructor(private service: PatientCardService, private snackService: SnackService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {

    if (this.form.invalid)
      return;

    const value = this.form.value;

    this.service.userForgetPassword(value.user, value.phone)
    .subscribe(
      data => {
        this.snackService.success(data);
        this.router.navigate(['/']);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
    
  }

}
