import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User } from '@app/auth/user';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-medical-card',
  templateUrl: './medical-card.component.html',
  styles: [
  ]
})
export class MedicalCardComponent implements OnInit, OnChanges {

  public patientCard: any = {};
  public photoUrl: SafeUrl | undefined;
  public qrUrl: SafeUrl | undefined;
  public qrSms: SafeUrl | undefined;

  public contraindications: any[] = [];
  public sensitization: any[] = [];
  public contactPersons: any[] = [];
  public attachedDevices: any[] = [];
  public diseasesManager: any[] = [];
  public user: any = {};

  @Input()
  loggedUser: User | null | undefined;

  @Input()
  revers: boolean = true;

  @Output()
  onPowiadom = new EventEmitter<void>();

  constructor(
    private readonly patientCardService: PatientCardService,
    //private readonly toast: ToastService,
    private sanitizer: DomSanitizer,
    private readonly snackService: SnackService,
    //public dialog: MatDialog,
   // private authService: AuthService,
    //private router: Router
  ) {
   // this.authService.currentUser.subscribe(x => this.islog = x);
  }


  private _reload() {
    this.patientCard = {};
    this.photoUrl = undefined;
    this.qrUrl = undefined;
    this.qrSms = undefined;
  
    this.contraindications = [];
    this.sensitization = [];
    this.contactPersons = [];
    this.attachedDevices = [];
    this.diseasesManager = [];
    this.user  = {};

    this.getAwers();
    this.getPhoto();

    if (this.revers) {
      this.getRewers();
      this.getQrSms();
      this.getQrUrl();
    }
  }

  ngOnInit(): void {
    this._reload();
  }

  ngOnChanges() {
    this._reload();
  }

  getAwers() {
    this.patientCardService.authGetMedicalCardAwers()
    .subscribe(
      data => {
        if (data !== null) {
          if (data.user) {
            this.user = data.user;
          }
          if (data.patient_card) {
            this.patientCard = data.patient_card;
          }
          if (Array.isArray(data.medical_informs?.contact_persons)) {
            this.contactPersons = data.medical_informs?.contact_persons;
          }
          if (Array.isArray(data.medical_informs?.diseasesManager)) {
            this.diseasesManager = data.medical_informs?.diseasesManager;
          }
        }
      },
      error => {
        // this.snackService.error(error.message);
      }
    );
  }

  getRewers() {
    this.patientCardService.authGetMedicalCardRewers()
    .subscribe(
      data => {
        if (data !== null) {
          if (Array.isArray(data.medical_informs?.contraindications)) {
            this.contraindications = data.medical_informs?.contraindications;
          }
          if (Array.isArray(data.medical_informs?.sensitization)) {
            this.sensitization = data.medical_informs?.sensitization;
          }
          if (Array.isArray(data.medical_informs?.attached_devices)) {
            this.attachedDevices = data.medical_informs?.attached_devices;
          }
        }
      },
      error => {
        // this.snackService.error(error.message);
      }
    );
  }

  getPhoto() {
    this.patientCardService.authGetPhoto().subscribe(
      data => {
        if (data != null && data.size > 0) {
          
        const objectURL = URL.createObjectURL(data);
        const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);

        this.photoUrl = img;
        }
      },
      error => {
      }
    );
  }

  getQrSms() {
    this.patientCardService.authGetQrSms().subscribe(
      data => {
        if (data != null && data.size > 0) {
          
        const objectURL = URL.createObjectURL(data);
        const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.qrSms = img;
        }
      },
      error => {
      }
    );
  }

  getQrUrl() {
    this.patientCardService.authGetQrUrl().subscribe(
      data => {
        if (data != null && data.size > 0) {
          
        const objectURL = URL.createObjectURL(data);
        const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.qrUrl = img;
        }
      },
      error => {
      }
    );
  }

}
