import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NewUserDataDto } from '@app/dto/new-user-data.dto';
import { UserDataDto } from '@app/dto/user-data.dto';
import { UserRegisterDataDto } from '@app/dto/user-register-data.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { SmsPinDialogComponent } from '@app/sms-pin-dialog/sms-pin-dialog.component';

import { AuthService, ConfirmedValidator } from './../auth/auth.service';



@Component({
  selector: 'app-register-data',
  templateUrl: './register-data.component.html',
  styles: [
  ]
})
export class UserDataComponent implements OnInit {
  private formSubmitAttempt!: boolean;
  private maxLength: number = 100;
  public registerData?: UserRegisterDataDto;
  private peselPattern: string = "[0-9]{11}";
  private pinPattern: string = "[0-9]{3,10}";

  public bloodGroups: string[] = ["0Rh-", "0Rh+", "ARh-", "ARh+", "BRh-", "BRh+", "ABRh-", "ABRh+"];



  form: FormGroup = this.fb.group({
    surname: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    firstname: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    email: ['', Validators.compose([Validators.required, Validators.maxLength(this.maxLength), Validators.email])],
    telephone: ['', Validators.compose([Validators.maxLength(this.maxLength)])],
    
    dateBirth: [null, Validators.compose([Validators.required])],
    sex: [null, Validators.required],
    height: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(299)])],
    weight: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(699)])],
    bloodGroup: [null, Validators.required],
    pesel: [null, Validators.compose([Validators.required, Validators.pattern(this.peselPattern)])],
    pin: [null, Validators.compose([Validators.required, Validators.pattern(this.pinPattern)])],
    city: [null, Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    zipCode: [null, Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    street: [null, Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    houseNumber: [null, Validators.compose([Validators.required, Validators.maxLength(this.maxLength)])],
    approvalsOrganTransplant: [null, Validators.required],

});

  constructor(
    private fb: FormBuilder,
    private patientCardService: PatientCardService,
    private route: ActivatedRoute,
    private router: Router,
    private snackService: SnackService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.patientCardService.authRegister()
    .subscribe(
      data => {
        if (data !== null) {
          this.registerData = data;

          if (data.pin != null) {
            this.router.navigate(['/']);
            return;
          }

          for (const [key, value] of Object.entries(data)) {       
            this.form.get(key)?.setValue((value === false || value === 0 || value === "") ? null : value);
          }

        }
      },
      error => {
        // this.toast.error(error.message);
        this.snackService.error(error.message);
      }
    );
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field)?.valid && this.form.get(field)?.touched) ||
      (this.form.get(field)?.untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      let newUser = this.form.value as UserRegisterDataDto;
      newUser.id = this.registerData?.id;
      newUser.userId = this.registerData?.userId;
      
      const dialogRef = this.dialog.open(SmsPinDialogComponent, {
        maxWidth: '100vw',
        panelClass: 'dialog-md',
        data: newUser
      });
      
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(["/"]);
        }
      });

    }
    this.formSubmitAttempt = true;
  }

}