<h1 mat-dialog-title>Zdjęcie</h1>
<mat-dialog-content>
<mat-form-field class="w-100">
  <ngx-mat-file-input [formControl]="fileControl" accept="image/*" placeholder="Wybierz plik">
  </ngx-mat-file-input>
  <mat-error *ngIf="fileControl.hasError('required')">To pole jest wymagane</mat-error>
  <mat-error *ngIf="fileControl.hasError('maxSize')">Plik jest zbyt duży</mat-error>
</mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
  <button mat-flat-button color="primary" (click)="onSubmit()">Wyślij</button>
</mat-dialog-actions>