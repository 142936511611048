<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Środek medyczny</h1>
  <div mat-dialog-content>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="example-full-width w-100" appearance="outline">
          <mat-label>Wyszukaj (min 3 znaki)</mat-label>
          <input matInput [matAutocomplete]="auto"
            [formControl]="searchControl">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)"
            >
            <mat-option *ngIf="searchLoading" class="is-loading">Ładowanie...</mat-option>
            <ng-container *ngIf="!searchLoading">
              <mat-option *ngFor="let option of filteredMedications" [value]="option">
                <span><b>{{option.nazwaProduktuLeczniczego}}</b> {{option.moc}}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-8">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nazwa</mat-label>
          <input matInput formControlName="medications" required>
          <mat-error *ngIf="dialogForm.controls['medications'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Moc</mat-label>
          <input matInput formControlName="strength">
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Stosowanie</mat-label>
          <textarea matInput formControlName="dosage"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Uwagi</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Data zapoczątkowania</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateFrom">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Data zaprzestania</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="dateTo">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" type="submit">Zapisz</button>
  </div>
  </form>
  