<mat-accordion *ngIf="help && help.length">
  <mat-expansion-panel class="c-first-aid mb-4">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="m-0">Procedury pierwszej pomocy</h5>
        <mat-icon class="c-first-aid-arrow">forward</mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="w-100">
      <ul class="fs-5 lh-lg">
        <li *ngFor="let item of help">{{ item }}</li>
      </ul>
    </div>

  </mat-expansion-panel>



</mat-accordion>
