<form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Uczulenie</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Uczulenie</mat-label>
          <textarea matInput formControlName="sitization" required></textarea>
          <mat-error *ngIf="dialogForm.controls['sitization'].hasError('required')">To pole jest wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Opis</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
    <button mat-flat-button color="primary" type="submit">Zapisz</button>
  </div>
  </form>
  