import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { User } from '../auth/user';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  public half: boolean = false;
  public transition: boolean = false;
  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isSmall$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    
    public islog?: User|null;
    constructor(private router: Router, private breakpointObserver: BreakpointObserver, private authService: AuthService) {
      this.authService.currentUser.subscribe(x => this.islog = x);
    }
    
  get isUser() {
    return this.islog?.role == "ROLE_USER";
  }


  public logout() {
    this.authService.signOut();
    this.router.navigate(['/']);
  }

  public onClick() {
    this.transition = true;
    this.half = !this.half;
  }

}
