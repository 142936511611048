  <form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
    <h4>Powiadom osoby kontaktowe</h4>
    <div mat-dialog-content>
      <mat-label>Zdarzenie</mat-label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select formControlName="description">
          <mat-option *ngFor="let option of options" [value]="option.shortDescription">
            {{ option.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-label>Treść</mat-label>
      <mat-form-field class="w-100" appearance="fill">
        <textarea matInput formControlName="text"></textarea>
      </mat-form-field>

      
    </div>
    <div mat-dialog-actions [align]="'end'">
      <button mat-flat-button cdkFocusInitial mat-dialog-close>Zamknij</button>
      <button mat-flat-button color="primary" type="submit">Wyślij</button>
    </div>
    </form>