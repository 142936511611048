import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttendingDoctorDto } from '@app/dto/patient-card/attending-doctor.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-doctor-edit-dialog',
  templateUrl: './doctor-edit-dialog.component.html'
})
export class DoctorEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    academicTitle: new FormControl(this.data?.academicTitle, Validators.required),
    doctorsName: new FormControl(this.data?.doctorsName, Validators.required),
    doctorsSurname: new FormControl(this.data?.doctorsSurname, Validators.required),
    lawNumperProfession: new FormControl(this.data?.lawNumperProfession),
    specialization: new FormControl(this.data?.specialization),
    phone: new FormControl(this.data?.phone),
    confirmed: new FormControl(this.data?.confirmed)
  });
  
  constructor(
    public dialogRef: MatDialogRef<DoctorEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: AttendingDoctorDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  ngOnInit(): void {
    // console.log(this.data);
  }

  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: AttendingDoctorDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: AttendingDoctorDto) {
    this.cardService.authAddDoctor(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: AttendingDoctorDto) {
    this.cardService.authUpdateDoctor(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
