import {Component} from '@angular/core';


@Component({
  templateUrl: 'settings-layout.component.html',
})
export class SettingsLayoutComponent {
  // public readonly menu: Link[] = [
  //   new Link('label.profile', ['/', 'patient', 'settings', 'profile']),
  //   new Link('label.changePassword', ['/', 'patient', 'settings', 'change-password']),
  // ];
}
