import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalInformsDto } from '@app/dto/patient-card/medical-informs.dto';
import { PatientCardResponseDto } from '@app/dto/patient-card/patient-card-response.dto';
import { VaccinationEditDialogComponent } from '../vaccination-edit-dialog/vaccination-edit-dialog.component';

@Component({
  selector: 'app-medical-infroms-dialog',
  templateUrl: './medical-infroms-dialog.component.html'
})
export class MedicalInfromsDialogComponent implements OnInit {

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: PatientCardResponseDto) { }


  ngOnInit(): void {
    // console.log(this.data);
  }


}
