<div class="px-4 px-lg-6">
  <h2 class="mb-3">Pulpit</h2>
  <mat-card class="mb-4" *ngIf="!islog">
    <p>Posiadasz Kod i PIN pacjenta? Wpisz je poniżej by zobaczyć jego kartę medyczną.</p>
    <form [formGroup]="form" autocomplete="off">
      <div class="d-flex justify-content-around align-items-center flex-wrap">
        <mat-form-field class="mx-2">
          <input matInput placeholder="Kod" formControlName="kod" required (keyup.enter)="pressEnter()">
        </mat-form-field>
        <mat-form-field class="mx-2">
          <input matInput placeholder="PIN" formControlName="pin" required maxlength="4" (keyup.enter)="pressEnter()">
        </mat-form-field>
        <!-- <div>
          <button mat-raised-button color="accent" type="submit">Wyślij</button>
        </div> -->
      </div>
    </form>
  </mat-card>

<app-first-aid></app-first-aid>

<app-medical-card [loggedUser]="islog" [revers]="false" (onPowiadom)="powiadom()"></app-medical-card>
  <div class="row">
    <div class="col">
      <div class="row">

        <div *ngFor="let card of cards" class="col-sm-6 col-md-6 mb-4">

          <div [routerLink]="card.link" class="medlife-summary-box u-cursor--pointer">
            <div class="d-flex">
              <div class="medlife-icon-box medlife-icon-box--{{ card.color}}">
                <svg-icon [name]="card.icon"></svg-icon>
              </div>
              <div class="d-flex flex-column justify-content-center ms-4 medlife-summary-box-content">
                <p class="fs-sm lh-sm fw-bold u-ls--normal mb-1">{{ card.title }}</p>
                <p class="m-0">{{ card.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>