import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '@app/services/snack.service';

import { AuthService } from './../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  private formSubmitAttempt!: boolean;
  private returnUrl: string = "/";

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackService: SnackService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field)?.valid && this.form.get(field)?.touched) ||
      (this.form.get(field)?.untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.authService.login(this.form.value.userName, this.form.value.password)
      .subscribe(
        data => {
          if (this.authService.checkToken(data)) {
            this.authService.signIn(data);
            this.router.navigate([this.returnUrl]);
          }
          else {
            // console.log('zly token');
          }
        },
        error => {
          if (error.status == 401) {
            this.snackService.error("Niepoprawny login lub hasło");
          }
          else {
            this.snackService.error(error.message);
          }
        }
      )
    }
    this.formSubmitAttempt = true;
  }
}