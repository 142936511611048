import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangeOldPasswordDto } from '@app/dto/change-old-password.dto';
import { ChangePasswordDto } from '@app/dto/change-password.dto copy';
import { Icd10Dto } from '@app/dto/icd10.dto';
import { Icd9Dto } from '@app/dto/icd9.dto';
import { AddictionDto as AddictionDto } from '@app/dto/patient-card/addiction.dto';
import { AttachedDeviceDto } from '@app/dto/patient-card/attached-device.dto';
import { AttendingDoctorDto } from '@app/dto/patient-card/attending-doctor.dto';
import { ContactPersonDto } from '@app/dto/patient-card/contact-person.dto';
import { ContraindicationDto } from '@app/dto/patient-card/contraindication.dto';
import { ExistingDiseaseDto } from '@app/dto/patient-card/existing-disease.dto';
import { MedicalHistoryDto } from '@app/dto/patient-card/medical-history.dto';
import { MedicalTreatmentDto } from '@app/dto/patient-card/medical-treatment.dto';
import { MedicationTakenDto } from '@app/dto/patient-card/medication-taken.dto';
import { SensitizationDto } from '@app/dto/patient-card/sensitization.dto';
import { VaccinationDto } from '@app/dto/patient-card/vaccination.dto';
import { ProduktLeczniczyDto } from '@app/dto/produkt-leczniczy.dto';
import { UserDataDto } from '@app/dto/user-data.dto';
import { UserRegisterDataDto } from '@app/dto/user-register-data.dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientCardResponseDto } from '../dto/patient-card/patient-card-response.dto';
import { MedicalResultDto } from '@app/dto/medical-result.dto';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class PatientCardService {

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {}

  authPatientCard(): Observable<PatientCardResponseDto> {
    return this.httpClient.get<PatientCardResponseDto>(`/auth/patient-card`);
  }

  authPatientCardAnonimSms(): Observable<PatientCardResponseDto> {
    return this.httpClient.get<PatientCardResponseDto>(`/auth/patient-card/userAnonim_SMS`);
  }

  getPatientCardByRole(role: string): Observable<PatientCardResponseDto> {
    if (role == "ROLE_ANONIM" || role == "ROLE_USERSMS") {
      return this.authPatientCardAnonimSms();
    }
    else {
      return this.authPatientCard();
    }
  }
  
  authRegister(): Observable<UserRegisterDataDto> {
    return this.httpClient.get<UserRegisterDataDto>(`/auth/register`);
  }

  authUpdateRegister(body: UserRegisterDataDto): Observable<any> {
    return this.httpClient.put(`/auth/register`, body, {
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }

  authRegisterSms(): Observable<any> {
    return this.httpClient.get(`/auth/register/telephone_activation`, {
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }

  authUserData(): Observable<UserDataDto> {
    return this.httpClient.get<UserDataDto>(`/auth/user-data`);
  }

  authAddMedicalTreatment(body: MedicalTreatmentDto): Observable<MedicalTreatmentDto> {
    return this.httpClient.post<MedicalTreatmentDto>(`/auth/patient-card-medical-treatments`, body);
  }

  authUpdateMedicalTreatment(body: MedicalTreatmentDto): Observable<MedicalTreatmentDto> {
    return this.httpClient.put<MedicalTreatmentDto>(`/auth/patient-card-medical-treatments`, body);
  }

  authDeleteMedicalTreatment(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-medical-treatments`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }


  authAddMedicalHistory(body: MedicalHistoryDto): Observable<MedicalHistoryDto> {
    return this.httpClient.post<MedicalHistoryDto>(`/auth/patient-card-medical-history`, body);
  }

  authUpdateMedicalHistory(body: MedicalHistoryDto): Observable<MedicalHistoryDto> {
    return this.httpClient.put<MedicalHistoryDto>(`/auth/patient-card-medical-history`, body);
  }

  authDeleteMedicalHistory(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-medical-history`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }


  authAddVaccination(body: VaccinationDto): Observable<VaccinationDto> {
    return this.httpClient.post<VaccinationDto>(`/auth/patient-card-vaccinations`, body);
  }

  authUpdateVaccination(body: VaccinationDto): Observable<VaccinationDto> {
    return this.httpClient.put<VaccinationDto>(`/auth/patient-card-vaccinations`, body);
  }

  authDeleteVaccination(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-vaccinations`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }
  
  
  authAddAddiction(body: AddictionDto): Observable<AddictionDto> {
    return this.httpClient.post<AddictionDto>(`/auth/patient-card-addictions`, body);
  }

  authUpdateAddiction(body: AddictionDto): Observable<AddictionDto> {
    return this.httpClient.put<AddictionDto>(`/auth/patient-card-addictions`, body);
  }

  authDeleteAddiction(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-addictions`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }
  
  
  authAddContraindication(body: ContraindicationDto): Observable<ContraindicationDto> {
    return this.httpClient.post<ContraindicationDto>(`/auth/patient-card-contraindications`, body);
  }

  authUpdateContraindication(body: ContraindicationDto): Observable<ContraindicationDto> {
    return this.httpClient.put<ContraindicationDto>(`/auth/patient-card-contraindications`, body);
  }

  authDeleteContraindication(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-contraindications`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }
  
  
  authAddSensitization(body: SensitizationDto): Observable<SensitizationDto> {
    return this.httpClient.post<SensitizationDto>(`/auth/patient-card-sensitization`, body);
  }

  authUpdateSensitization(body: SensitizationDto): Observable<SensitizationDto> {
    return this.httpClient.put<SensitizationDto>(`/auth/patient-card-sensitization`, body);
  }

  authDeleteSensitization(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-sensitization`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }

  authAddContactPerson(body: ContactPersonDto): Observable<ContactPersonDto> {
    return this.httpClient.post<ContactPersonDto>(`/auth/patient-card-contact-persons`, body);
  }
  
  authUpdateContactPerson(body: ContactPersonDto): Observable<ContactPersonDto> {
    return this.httpClient.put<ContactPersonDto>(`/auth/patient-card-contact-persons`, body);
  }
  
  authDeleteContactPerson(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-contact-persons`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }


  authAddDoctor(body: AttendingDoctorDto): Observable<AttendingDoctorDto> {
    return this.httpClient.post<AttendingDoctorDto>(`/auth/patient-card-attending-doctors`, body);
  }
  
  authUpdateDoctor(body: AttendingDoctorDto): Observable<AttendingDoctorDto> {
    return this.httpClient.put<AttendingDoctorDto>(`/auth/patient-card-attending-doctors`, body);
  }
  
  authDeleteDoctor(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-attending-doctors`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }


  authAddExistingDisease(body: ExistingDiseaseDto): Observable<ExistingDiseaseDto> {
    return this.httpClient.post<ExistingDiseaseDto>(`/auth/patient-card-existing-diseases`, body);
  }
  
  authUpdateExistingDisease(body: ExistingDiseaseDto): Observable<ExistingDiseaseDto> {
    return this.httpClient.put<ExistingDiseaseDto>(`/auth/patient-card-existing-diseases`, body);
  }
  
  authDeleteExistingDisease(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-existing-diseases`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }


  authAddMedicationTaken(body: MedicationTakenDto): Observable<MedicationTakenDto> {
    return this.httpClient.post<MedicationTakenDto>(`/auth/patient-card-medications-taken`, body);
  }
  
  authUpdateMedicationTaken(body: MedicationTakenDto): Observable<MedicationTakenDto> {
    return this.httpClient.put<MedicationTakenDto>(`/auth/patient-card-medications-taken`, body);
  }
  
  authDeleteMedicationTaken(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-medications-taken`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }


  authAddAttachedDevice(body: AttachedDeviceDto): Observable<AttachedDeviceDto> {
    return this.httpClient.post<AttachedDeviceDto>(`/auth/patient-card-attached-devices`, body);
  }
  
  authUpdateAttachedDevice(body: AttachedDeviceDto): Observable<AttachedDeviceDto> {
    return this.httpClient.put<AttachedDeviceDto>(`/auth/patient-card-attached-devices`, body);
  }
  
  authDeleteAttachedDevice(id: number): Observable<any> {
    return this.httpClient.delete(`/auth/patient-card-attached-devices`, {
      params: new HttpParams().set('id', id),
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }



  
  authGetPhoto(): Observable<any> {
    return this.httpClient.get(`/auth/get-photo`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: "blob"
    });
  }

  authUploadPhoto(body: any): Observable<any> {
    return this.httpClient.post<any>(`/auth/upload-photo`, body);
  }

  authUpdatePhoto(body: any): Observable<any> {
    return this.httpClient.put<any>(`/auth/upload-photo`, body);
  }

  // autocomplete:

  authIcd9ByKeyword(search: string): Observable<Icd9Dto[]> {
    return this.httpClient.get<string[]>(`/auth/icd9_by_keyword/${search}`).pipe(

      map(res => res.map(item => {
        let [first, ...rest] = item.split(' ')
        return {
          numer: first,
          tytul: rest.join(' '),
          keyword: item
        };
      }))
    );
  }

  authIcd10ByKeyword(search: string): Observable<Icd10Dto[]> {
    return this.httpClient.get<string[]>(`/auth/icd10_by_keyword/${search}`).pipe(

      map(res => res.map(item => {
        let [first, ...rest] = item.split(' ')
        return {
          code: first,
          name: rest.join(' '),
          keyword: item
        };
      }))
    );
  }

  authLekiByKeyword(search: string): Observable<ProduktLeczniczyDto[]> {
    return this.httpClient.get<string[]>(`/auth/leki_by_keyword/${search}`).pipe(

      map(res => res.map(item => {
        let [first, second, ...rest] = item.split('|')
        return {
          kodAtc: first,
          nazwaProduktuLeczniczego: second,
          moc: rest.join(' '),
          keyword: item,
        };
      }))
    );
  }

  authSubstancjeByKeyword(search: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`/auth/substancje_czynne_by_keyword/${search}`);
  }

  // karta medyczna

  authGetMedicalCardAwers(): Observable<any> {
    return this.httpClient.get(`/auth/medical_card/awers`);
  }

  authGetMedicalCardRewers(): Observable<any> {
    return this.httpClient.get(`/auth/medical_card/rewers`);
  }
  
  authGetQrSms(): Observable<any> {
    return this.httpClient.get(`/auth/get-qr-sms`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: "blob"
    });
  }

  authGetQrUrl(): Observable<any> {
    return this.httpClient.get(`/auth/get-qr-url`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: "blob"
    });
  }

  userDictionary<T>(dictionary: string): Observable<T[]> {
    return this.httpClient.get<T[]>(`/user/dictionary/${dictionary}`);
  }

  userSmsTrustedSending(description: string, text: string): Observable<any> {
    return this.httpClient.get(`/user/sms_trusted_sending/${description}/{text}`, {
      params: new HttpParams().set('text', text),
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }
  authChangePassword(body: ChangeOldPasswordDto): Observable<any> {
    return this.httpClient.put(`/auth/change_oldPaddword_newPassword`, body, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }
  userForgetPassword(username: string, telephone: string): Observable<any> {
    return this.httpClient.get(`/user/forget_password/${username}/{telephone}`, {
      params: new HttpParams().set('telephone', telephone),
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  

  userChangePasswordToken(body: ChangePasswordDto): Observable<any> {
    return this.httpClient.put(`/user/change_password`, body, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  // UPDATE

  medicalResults(): Observable<MedicalResultDto[]> {
    return this.httpClient.get<MedicalResultDto[]>(`/medicals-results/all-results`);
  }

  saveMedicalResult(result: MedicalResultDto): Observable<MedicalResultDto> {
    return this.httpClient.post<MedicalResultDto>(`/medicals-results/save-results`, result);
  }

  userDictionaryGeneral(dictionary: string): Observable<any> {
    return this.httpClient.get(`/user/dictionary/general/{parametr}?parametr=${dictionary}`);
  }


  getFileResult(id: number): Observable<any> {
    return this.httpClient.get(`/medicals-results/get-results-foto/id/${id}`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: "blob"
    }).pipe(
      map(data => {
        const objectURL = URL.createObjectURL(data);
        const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return img;
      })
    );
  }
  
  uploadFileResult(type: string, body: any): Observable<any> {
    return this.httpClient.post<any>(`/medicals-results/save-results-foto/medicalsResults/${type}`, body);
  }

  deleteMedicalResult(id: number): Observable<any> {
    return this.httpClient.delete(`/medicals-results/del-results/id/${id}`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  userDictionaryIcdHelp(icd: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`/user/dictionary/icd-help/${icd}`);
  }


}
