import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContraindicationDto } from '@app/dto/patient-card/contraindication.dto';
import { ProduktLeczniczyDto } from '@app/dto/produkt-leczniczy.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-contraindication-edit-dialog',
  templateUrl: './contraindication-edit-dialog.component.html'
})
export class ContraindicationEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    contraindications: new FormControl(this.data?.contraindications, Validators.required),
    description: new FormControl(this.data?.description)
  });
  
  constructor(
    public dialogRef: MatDialogRef<ContraindicationEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: ContraindicationDto,
  private cardService: PatientCardService,
  private snackService: SnackService) { }

  searchControl = new FormControl(null);
  filteredMedications: any;
  searchLoading = false;
  private search: string = "";

  onSelected(event: any) {
    this.searchControl.setValue(null);
    this.dialogForm.controls['contraindications'].setValue(event.option.value);
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.filteredMedications = [];
          this.searchLoading = true;
        }),
        switchMap(value => {
          this.search = value;
          return this.cardService.authSubstancjeByKeyword(value);
        })
      )
      .subscribe(
        (data: any) => {
        if (data == undefined) {
          this.filteredMedications = [];
        } else {
          this.filteredMedications = this.toSet(data, this.search);
        }
        this.searchLoading = false;
      },
      error => {
        this.searchLoading = false;
      });
  }

  toSet(substancje: string[], search: string): Array<string> {
    const set = new Set<string>();

    substancje.forEach(s => {
      // console.log(s);
      let sArray = s.split(" + ");
      // console.log(sArray);
      sArray.forEach(a => {
        if (a.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          set.add(this.capitalizeFirstLetter(a.trim()));
        }
      })
    });
    
    return [...set].sort();
  }

  private capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: ContraindicationDto = this.dialogForm.value;
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: ContraindicationDto) {
    this.cardService.authAddContraindication(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: ContraindicationDto) {
    this.cardService.authUpdateContraindication(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
