
  import { formatDate } from '@angular/common';
  import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
  import { ContraindicationDto } from '@app/dto/patient-card/contraindication.dto';
  import { PatientCardService } from '@app/patient-card/patient-card.service';
  import { SnackService } from '@app/services/snack.service';
  
  @Component({
    selector: 'app-sms-dialog',
    templateUrl: './phone-dialog.component.html',
    styles: [
    ]
  })
  export class PhoneDialogComponent implements OnInit {
  
    formControl: FormControl = new FormControl("", Validators.compose([Validators.pattern('[- +()0-9]{6,}'), Validators.required]));
    formControlPIN: FormControl = new FormControl("", Validators.required);
    
    constructor(
      public dialogRef: MatDialogRef<PhoneDialogComponent>,
    private authService: AuthService,
    private snackService: SnackService) { }
  
    ngOnInit(): void {
    }

  
    onSend() {
      
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();

      if (this.formControl.invalid)
        return;
  
      const phone: string = this.formControl.value;
      
      this.authService.ratownikSMS(this.authService.currentUserValue?.username||'', phone)
      .subscribe(
        data => {
          this.snackService.success(data);
        },
        error => {
            this.snackService.error(error.message);
        }
      )
    }
  
  
    onSubmit() {

      this.formControlPIN.markAsTouched();
      this.formControlPIN.markAsDirty();
      
      if (this.formControlPIN.invalid)
        return;
  
      const pass: string = this.formControlPIN.value;
      
      this.authService.loginSMS(this.authService.currentUserValue?.username||'', pass)
      .subscribe(
        data => {
          if (this.authService.checkToken(data)) {
            this.authService.signIn(data);
            this.dialogRef.close(true);
            this.snackService.success("Pomyślnie zalogowano");
          }
          else {
            // console.log('zly token');
            this.snackService.error("Nieoczekiwany błąd");
          }
        },
        error => {
          if (error.status == 401) {
            this.snackService.error("Niepoprawny kod");
          }
          else {
            this.snackService.error(error.message);
          }
        }
      )
    }

  }
  