import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { User } from '@app/auth/user';
import { UserPermissionDto } from '@app/dto/user-permission.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styles: [
  ]
})
export class SettingsComponent implements OnInit {

  constructor(private authService: AuthService, private snackService: SnackService) { }

  public permissions: UserPermissionDto[] = [];

  public readonly listOptions:  string[] = [
    "Karta pacjenta",
    "Istniejące choroby",
    "Przebyte choroby",
    "Zabiegi",
    "Szczepienia",
    "Uczulenia",
    "Przeciwwskazania",
    "Nałogi",
    "Osoby kontaktowe",
    "Lekarze prowadzący",
    "Zażywane leki",
    "Urządzenia"
  ];

  getPermissions(to: string): UserPermissionDto | undefined {
    return this.permissions.find(p => p.permissionsTo == to);
  }

  ngOnInit(): void {
    
    this.authService.getPermissions()
    .subscribe(
      data => {
        if (data !== null) {
          this.permissions = data;
        }
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
