<!-- <mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/">Start</a>
      <a mat-list-item routerLink="patient-card">Karta pacjenta</a>
      <a mat-list-item routerLink="my-medical-card" *ngIf="isUser">Moja karta medyczna</a>
      <mat-expansion-panel [class.mat-elevation-z0]="true" class="list-item-expanded" *ngIf="isUser">
        <mat-expansion-panel-header mat-ripple>
            Ustawienia
        </mat-expansion-panel-header>
        <div>
          <a mat-list-item routerLink="settings/permissions">Uprawnienia</a>
          <a mat-list-item routerLink="settings/profile">Profil</a>
        </div>
    </mat-expansion-panel>
    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Medkart</span>
      <span class="flex-grow-1"></span>
      <ng-container *ngIf="!islog">
        <a mat-button routerLink="register">Zarejestruj</a>
        <a mat-button routerLink="login">Zaloguj</a>
      </ng-container>
      <ng-container *ngIf="islog">
        <a mat-button (click)="logout()">Wyloguj</a>
      </ng-container>
    </mat-toolbar>
    <div class="p-3">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container> -->


<mat-sidenav-container class="sidenav-container" [ngClass]="{'mat-drawer-transition' : transition}">
  <mat-sidenav
    #drawer
    class="sidenav"
    [ngClass]="{'half' : half}"
    fixedInViewport
    [attr.role]="(isSmall$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isSmall$ | async) ? 'over' : 'side'"
    [opened]="(isSmall$ | async) === false"
  >
    <div class="medlife-sidebar">
      <button
        (click)="onClick()"
        class="colbut medlife-toggle-button"
        [style.pointerEvents]="!drawer.opened&&(isSmall$ | async) ? 'none' : 'auto'"
      >
        <svg-icon name="arrow"></svg-icon>
      </button>
      <div class="medlife-sidebar-top">
        <mat-toolbar class="p-0">
          <div class="medlife-logo">
            <svg-icon name="heart"></svg-icon>
          </div>
          <span class="qwe">Medkart</span>
        </mat-toolbar>
      </div>
      <div class="medlife-sidebar-nav">
        <div class="medlife-nav-list">
          <a
            class="medlife-nav-list-item"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/']"
          >
            <svg-icon name="home"></svg-icon><span class="qwe">Pulpit</span>
          </a>

          <a
            class="medlife-nav-list-item"
            routerLinkActive="active"
            [routerLink]="['/','patient-card']"
          >
            <svg-icon name="medic_card"></svg-icon><span class="qwe">Karta pacjenta</span>
          </a>

          <a *ngIf="isUser"
            class="medlife-nav-list-item"
            routerLinkActive="active"
            [routerLink]="['/','my-medical-card']"
          >
            <svg-icon name="projects"></svg-icon><span class="qwe">Moja karta med.</span>
          </a>

          <a *ngIf="isUser"
            class="medlife-nav-list-item"
            routerLinkActive="active"
            [routerLink]="['/', 'settings']"
          >
            <svg-icon name="settings"></svg-icon><span class="qwe">Ustawienia</span>
          </a>

          <span class="medlife-list-spacer"></span>

          <ng-container *ngIf="islog">
            <a class="medlife-nav-list-item" (click)="logout()">
              <svg-icon name="logout"></svg-icon><span class="qwe">Wyloguj</span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [style.marginLeft]="(isSmall$ | async) ? '0': (half ? '104px' : '240px')">
    <mat-toolbar class="px-4 px-lg-6">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isSmall$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <span class="flex-grow-1"></span>

      
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
