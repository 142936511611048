import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalTreatmentDto } from '@app/dto/patient-card/medical-treatment.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';
@Component({
  selector: 'app-medical-treatment-edit-dialog',
  templateUrl: './medical-treatment-edit-dialog.component.html'
})
export class MedicalTreatmentEditDialogComponent implements OnInit {

  dialogForm = new FormGroup({
    treatments: new FormControl(this.data?.treatments, Validators.required),
    dateTreatments: new FormControl(this.data?.dateTreatments, Validators.required),
    codeIcd9: new FormControl(this.data?.codeIcd9, Validators.required),
    description: new FormControl(this.data?.description)
  });
  
  constructor(
    public dialogRef: MatDialogRef<MedicalTreatmentEditDialogComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: MedicalTreatmentDto,
  private cardService: PatientCardService,
  private snackService: SnackService) {}

  searchControl = new FormControl(null);
  filteredTreatments: any;
  searchLoading = false;

  onSelected(event: any) {
    this.searchControl.setValue(null);
    this.dialogForm.controls['treatments'].setValue(event.option.value.tytul);
    this.dialogForm.controls['codeIcd9'].setValue(event.option.value.numer);
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.filteredTreatments = [];
          this.searchLoading = true;
        }),
        switchMap(value => this.cardService.authIcd9ByKeyword(value))
      )
      .subscribe(
        (data: any) => {
        if (data == undefined) {
          this.filteredTreatments = [];
        } else {
          this.filteredTreatments = data;
        }
        this.searchLoading = false;
      },
      error => {
        this.searchLoading = false;
      });
  }


  onSubmit() {
    
    if (this.dialogForm.invalid)
      return;

    const item: MedicalTreatmentDto = this.dialogForm.value;

    if (this.dialogForm.value.treatments.tytul) {
      item.treatments = this.dialogForm.value.treatments.tytul;
    }
    
    if (this.data.id != null) { // edit
      item.id = this.data.id;
      item.userId = this.data.userId;
      item.dateAdd = this.data.dateAdd;
      this.updateItem(item);
    }
    else { // insert
      item.userId = this.data.userId;
      item.dateAdd = formatDate(Date.now(), "yyyy-MM-dd", this.locale);
      this.addItem(item);
    }
  }

  addItem(item: MedicalTreatmentDto) {
    this.cardService.authAddMedicalTreatment(item)
    .subscribe(
      data => {
        this.snackService.success("Dodano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

  updateItem(item: MedicalTreatmentDto) {
    this.cardService.authUpdateMedicalTreatment(item)
    .subscribe(
      data => {
        this.snackService.success("Zaktualizowano element");
        this.dialogRef.close(data);
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }

}
