import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
import { ContraindicationDto } from '@app/dto/patient-card/contraindication.dto';
import { UserRegisterDataDto } from '@app/dto/user-register-data.dto';
import { PatientCardService } from '@app/patient-card/patient-card.service';
import { SnackService } from '@app/services/snack.service';

@Component({
  selector: 'app-sms-pin-dialog',
  templateUrl: './sms-pin-dialog.component.html',
  styles: [
  ]
})
export class SmsPinDialogComponent implements OnInit {

  formControl: FormControl = new FormControl("", Validators.required);
  
  constructor(
    public dialogRef: MatDialogRef<SmsPinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserRegisterDataDto,
  private patientCardService: PatientCardService,
  private snackService: SnackService) { }

  ngOnInit(): void {
    this.patientCardService.authRegisterSms()
    .subscribe(
      data => {
      },
      error => {
        this.snackService.error(error.message);
      }
    );
  }


  onSubmit() {
    
    if (this.formControl.invalid)
      return;

    const smsPin: string = this.formControl.value;
    this.data.smsPin = smsPin;

    this.patientCardService.authUpdateRegister(this.data)
    .subscribe(
      data => {
        this.snackService.success("Zapisano dane");
        this.dialogRef.close(true);
      },
      error => {
        if (error.status == 401) {
          this.snackService.error("Błędny pin");
        }
        else {
          this.snackService.error(error.message);
        }
      }
    );
  }


}
