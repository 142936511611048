<div class="px-4 px-lg-6">

      <ng-container *ngIf="!complete">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <h2 class="mb-3">Rejestracja</h2>

          <mat-card>
          <div class="row mb-3">

            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput placeholder="Imię" formControlName="firstname" required> 
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput placeholder="Nazwisko" formControlName="surname" required>
              </mat-form-field>
            </div>

            <!-- <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput placeholder="Login" formControlName="username" required>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <mat-label>Płeć</mat-label>
                <mat-select formControlName="sex" required>
                  <mat-option value="K">Kobieta</mat-option>
                  <mat-option value="M">Mężczyzna</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput type="email" placeholder="E-mail" formControlName="email" required>
                <mat-error *ngIf="form.controls['email'].errors?.email">Wprowadź poprawny adres e-mail</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput type="tel" placeholder="Numer telefonu" formControlName="telefon" required>
              </mat-form-field>
            </div>
            
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput type="password" placeholder="Hasło" formControlName="password" required>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="w-100">
                <input matInput type="password" placeholder="Potwierdź hasło" formControlName="confirm" required>
                <mat-error *ngIf="form.controls['confirm'].errors?.confirmedValidator">Hasła nie są takie same</mat-error>
              </mat-form-field>
            </div>

            <!-- <div class="col-sm-4">
              <mat-form-field class="w-100">
                <input matInput type="number" min="0" placeholder="Waga" formControlName="weight" required>
                <mat-error *ngIf="form.controls['tall'].errors?.max">Podawana wartość jest zbyt duża</mat-error>
                <mat-error *ngIf="form.controls['tall'].errors?.min">Wartość nie może być ujemna</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field class="w-100">
                <input matInput type="number" min="0" placeholder="Wzrost" formControlName="tall" required>
                <mat-error *ngIf="form.controls['tall'].errors?.max">Podawana wartość jest zbyt duża</mat-error>
                <mat-error *ngIf="form.controls['tall'].errors?.min">Wartość nie może być ujemna</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field class="w-100">
                <input matInput type="date" placeholder="Data urodzenia" formControlName="dateBirth" required>
              </mat-form-field>
            </div> -->
          </div>

          <button mat-raised-button color="primary" type="submit">Zarejestruj</button>
        </mat-card>
        </form>
      </ng-container>
      <ng-container *ngIf="complete">
        Dziękujemy za rejestrację. Twoja nazwa użytkownika to: <b>{{ username }}</b>. Użyj jej do zalogowania w systemie.
      </ng-container>
</div>
