
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
// import {config} from '../config/api.config';
import { JwtHelperService } from "@auth0/angular-jwt";
import {tap} from 'rxjs/operators';
import { User } from './user';
import { Router } from '@angular/router';
import { NewUserDataDto } from '@app/dto/new-user-data.dto';
import { FormGroup } from '@angular/forms';
import { UserPermissionDto } from '@app/dto/user-permission.dto';



// =============' KEY IN LOCALSTORAGE '==============
const TOKEN_KEY   = 'auth-token';
const USER_KEY    = 'auth-user';
const ROLES_KEY   = 'auth-roles';
// =================================================


export type PermissionString = "PatientCardExistingDiseases" | "PatientCardMedicalHistory" | "PatientCardMedicalTreatments" | "PatientCardVaccinations" | "PatientCardSensitization" | "PatientCardContraindications" | "PatientCardAddictions" | "PatientCardContactPersons" | "PatientCardAttendingDoctors" | "PatientCardMedicationsTaken" | "PatientCardAttachedDevices" | "PatientCardPhoto" | "PatientCard" | "UserData";


@Injectable()
export class AuthService {

  private jwtHelper = new JwtHelperService();

  private editPermissions: PermissionString[]|null = null;
  private previewPermissions: PermissionString[]|null = null;
  private currentUserSubject: BehaviorSubject<User|null>;
  public currentUser: Observable<User|null>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User|null>(this.getCurrentUser());
    this.currentUser = this.currentUserSubject.asObservable();
}

reloadCurrentRoute() {
  let currentUrl = this.router.url;
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
  });
}

public get currentUserValue(): User|null {
  return this.currentUserSubject.value;
}

private clearPermissions() {
  this.editPermissions = null;
  this.previewPermissions = null;
}

private getCurrentUser(): User|null {
  const token = this.getToken();
  // console.log(token)
  if (token == null) {
    return null;
  }
  try {
    const decodedToken = this.jwtHelper.decodeToken(token);
    const user = {
      username: decodedToken.sub,
      role: decodedToken.role
    };
    return user;
  } catch(_) {
    return null;
  }
  
}

hasPermission(permission: PermissionString, mode: "edit"|"preview" = "preview"): boolean {

  if (!Array.isArray(this.editPermissions) || !Array.isArray(this.previewPermissions)) {
    const role = this.currentUserValue?.role || "";
    this.editPermissions = [];
    this.previewPermissions = [];
    this.getPermissionsByRole(role).subscribe(
      data => {
        if (data != null) {
        this.preparePermissions(data, role);
        }
      },
      error => {
        this.clearPermissions();
      }
    );
  }
  return (mode == "edit" ? this.editPermissions.includes(permission) : this.previewPermissions.includes(permission));
}

private preparePermissions(data: UserPermissionDto[], role: string) {
  data.forEach(p => {
    if (role == "ROLE_USER" && p.roleUserPreview) this.previewPermissions?.push(p.permissionsTo as PermissionString);
    if (role == "ROLE_USER" && p.roleUserEdit) this.editPermissions?.push(p.permissionsTo as PermissionString);
    if (role == "ROLE_ANONIM" && p.roleAnonimPreview) this.previewPermissions?.push(p.permissionsTo as PermissionString);
    if (role == "ROLE_USERSMS" && p.roleUsersmsPreview) this.previewPermissions?.push(p.permissionsTo as PermissionString);
  });

  // tymczasowe
  this.previewPermissions?.push("PatientCardPhoto");
  if (role == "ROLE_USER") this.editPermissions?.push("PatientCardPhoto");
}

  /**
   * Function for Connecting User
   * to the backend and generating JWT
   *
   * @param user
   */
  // tslint:disable-next-line:typedef
  login(username: string, password: string): Observable<any> {
    return this.http.get(`/user/authorization-user/${username}/{password}`, {
      params: new HttpParams().set('password', password),
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  loginAnonim(username: string, pin: string): Observable<any> {
    return this.http.get(`/user/authorization-anonim/${username}/{pin}`, {
      params: new HttpParams().set('pin', pin),
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  sendSMS(username: string): Observable<any> {
    return this.http.get(`/user/get-authorization-sms/${username}`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  ratownikSMS(username: string, phone: string): Observable<any> {
    return this.http.get(`/user/get-authorization-sms/${username}/{telephone_help}?telephone_help=${phone}`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  loginSMS(username: string, pass: string): Observable<any> {
    return this.http.get(`/user/authorization-sms/${username}/{smsPass}`, {
      params: new HttpParams().set('smsPass', pass),
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  register(body: NewUserDataDto): Observable<any> {
    return this.http.post(`/new-user/register`, body, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  getPermissions(): Observable<UserPermissionDto[]> {
    return this.http.get<UserPermissionDto[]>(`/auth/user-permissions`);
  }

  getPermissionsAnonimSms(): Observable<UserPermissionDto[]> {
    return this.http.get<UserPermissionDto[]>(`/auth/user-permissions/userAnonim_SMS`);
  }

  getPermissionsByRole(role: string): Observable<UserPermissionDto[]> {
    if (role == "ROLE_ANONIM" || role == "ROLE_USERSMS") {
      return this.getPermissionsAnonimSms();
    }
    else {
      return this.getPermissions();
    }
  }

  updatePermissions(body: UserPermissionDto): Observable<any> {
    this.clearPermissions();
    return this.http.put(`/auth/user-permissions`, body, {
      headers: new HttpHeaders().set('Accept','text/*'),
      responseType: 'text'
    });
  }

  // /**
  //  * Function for Registering a new User
  //  *
  //  * @param user
  //  */
  // register(user: { username: string, email: string, password: string }): Observable<any> {
  //   return this.http.post(`${config.apiBaseUrl}/login`, user);
  // }



  /**
   * Verify if User is Connected
   */
  isLoggedIn(): boolean {
    return !!this.getToken();
  }
  // =================================================
  // 🔐🔐 TOKEN MANAGER 🔐🔐
  // =================================================

  /**
   * Clears the localstorage and Disconnect the User
   * from the Front-End
   *
   */
  signOut(): void {
    localStorage.clear();
    this.clearPermissions();
    if (this.currentUserSubject)
      this.currentUserSubject.next(null);
  }

  logout(): void {
    this.signOut();
    this.reloadPage();
  }

  private reloadPage(): void {
    window.location.reload();
  }

  signIn(token: string): void {
    this.clearPermissions();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
    this.currentUserSubject.next(this.getCurrentUser());
  }



  /**
   * Get The Token Stored in LocalStorage
   * ¤ If Exist
   */
  public getToken(): string | null {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token != null) {
      if (!this.checkToken(token)) {
        this.logout();
        return null;
      }
    }
    return token;
  }


  public checkToken(token: string): boolean {
    try {
      this.jwtHelper.decodeToken(token);
      return !this.jwtHelper.isTokenExpired(token);
    }
    catch(_) {
      return false;
    }
  }

  /**
   * Stores The Connected User and His Role in the System
   *
   * @param token
   */
  public saveUser(token: any): void {
    localStorage.removeItem(USER_KEY);
    // ============================================
    /**
     * Uses the sub property in the Crypted JWT Token
     */
    localStorage.setItem(USER_KEY, this.jwtHelper.decodeToken(token).sub);
    // ============================================
    /**
     * If you got many roles for one user you need to replace this under line by this commented one :
     * localStorage.setItem(ROLES_KEY, this.jwtHelper.decodeToken(token).roles[0]);
     */
    localStorage.setItem(ROLES_KEY, this.jwtHelper.decodeToken(token).roles[0].authority);


  }

  /**
   * Get The User Stored in LocalStorage
   * ¤ If Exist
   */
  public getUser(): boolean | string {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return user;
    }
    return false;
  }

  /**
   * Get The Role of the connected User Stored in LocalStorage
   * ¤ If Exist
   */
  public getUserRole(): string | null {
    const role = localStorage.getItem(ROLES_KEY);
    if (role){
      return role;
    }
    return null;
  }
  // =================================================
  // END TOKEN MANAGEMENT
  // =================================================

}
  
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }

      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}